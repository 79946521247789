@charset "UTF-8";
/* cyrillic-ext */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/MerriweatherItalic-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/MerriweatherItalic-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/Merriweather-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/Merriweather-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/Merriweather-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/Merriweather-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/Merriweather-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/Merriweather-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/Merriweather-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/Merriweather-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/Merriweather-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/Merriweather-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/**
 * Enhanced
 * Bootstrap the rest of the styles on cut the mustard
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Default link style
 *
 */
/**
 * Link Text
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
/**
 * Basic
 * Some default CSS styles
 */
body {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.8;
}

a {
  color: #025e8d;
}

button {
  cursor: pointer;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * Typography
 * Set base font size
 */
html {
  font-size: 100%;
}

body {
  font-size: 1.125rem;
}

/*
 * Headings
 */
h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

/**
 * Layout
 * Universal layout styles
 */
html {
  height: 100%;
  overflow-y: scroll;
  font-size: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100%;
  font-size: 1.125rem;
  color: #222222;
  background: #ffffff;
}

abbr[title] {
  text-decoration: none;
}

dd {
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 2rem;
}

p:last-of-type {
  margin-bottom: 0;
}

a {
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
a.visited, a:visited {
  color: #01324b;
}
a.hover, a:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
a.active, a:active {
  color: #025e8d;
}
a.focus, a:focus {
  outline: 4px solid #0088cc;
}

table {
  font-size: 1rem;
  line-height: 1.8;
  border-collapse: collapse;
  border: none;
  width: 100%;
  margin-bottom: 32px;
}

th,
td {
  padding: 8px;
  vertical-align: top;
}

th {
  text-align: left;
  border-bottom: 3px solid #01324b;
}

td {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 2px solid #dadada;
}

/**
 * Forms
 * Default form styles
 */
button:not([disabled]):focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus,
[contenteditable]:focus {
  outline: 4px solid #0088cc;
}

input[type=file]:focus-within {
  outline: 4px solid #0088cc;
}

/**
 * @springernature/global-ad
 * Default skin settings
 *
 */
.c-ad {
  text-align: center;
}
@media only screen and (min-width: 480px) {
  .c-ad {
    padding: 8px;
  }
}

.c-ad--728x90,
.c-ad--970x90 {
  display: none;
  background-color: #ccc;
}
.c-ad--728x90 .c-ad__inner,
.c-ad--970x90 .c-ad__inner {
  min-height: calc(1.5em + 4px + 90px);
}
.c-ad--728x90 iframe,
.c-ad--970x90 iframe {
  max-width: 970px;
  height: 90px;
}

@media only screen and (min-width: 876px) {
  .js .c-ad--728x90 {
    display: none;
  }
}

@media only screen and (min-width: 876px) {
  .js .u-show-following-ad + .c-ad--728x90 {
    display: block;
  }
}

.c-ad--160x600 {
  display: none;
  padding: 0;
  text-align: left;
}
.c-ad--160x600 iframe {
  width: 160px;
  height: 600px;
}

@media only screen and (min-width: 876px) {
  .js .c-ad--160x600 {
    display: block;
  }
}

.c-ad--300x250 {
  display: none;
  background-color: #f2f2f2;
  padding: 8px;
}
.c-ad--300x250 iframe {
  width: 300px;
  height: 250px;
}
.c-ad--300x250 .c-ad__inner {
  min-height: calc(1.5em + 4px + 250px);
}

@media only screen and (min-width: 480px) {
  .js .c-ad--300x250 {
    display: block;
  }
}

.c-ad iframe {
  border: none;
  overflow: auto;
  vertical-align: top;
}

.c-ad__label {
  font-size: 0.875rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-bottom: 4px;
  color: #333;
  line-height: 1.5;
}

/**
 * @springernature/global-author-list
 * Default skin settings
 *
 */
.c-author-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: inherit;
  color: black;
  line-height: inherit;
}
.c-author-list > li {
  display: inline;
}
.c-author-list > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.c-author-list > li:not(:only-child):last-child::before {
  content: " & ";
}

.c-author-list--compact {
  line-height: 1.4;
  font-size: 0.875rem;
}

.c-author-list--truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.js .c-author-list {
  display: inline;
}
.js .c-author-list__hide {
  display: none;
  visibility: hidden;
}
.js .c-author-list__hide:first-child + * {
  margin-block-start: 0;
}

/**
 * Status Message
 * Default branding for component
 */
.c-status-message {
  display: flex;
  align-items: center;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.c-status-message *:last-child {
  margin-bottom: 0px;
}

.c-status-message--boxed {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #cccccc;
  line-height: 1.4;
  padding: 16px;
}

.c-status-message__heading {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
}

.c-status-message__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 auto;
  margin-right: 8px;
}

.c-status-message__icon--top {
  align-self: flex-start;
}

.c-status-message--info .c-status-message__icon {
  color: #003f8d;
}

.c-status-message--boxed.c-status-message--info {
  border-bottom: 4px solid #003f8d;
}

.c-status-message--error .c-status-message__icon {
  color: #c40606;
}

.c-status-message--boxed.c-status-message--error {
  border-bottom: 4px solid #c40606;
}

.c-status-message--success .c-status-message__icon {
  color: #00b8b0;
}

.c-status-message--boxed.c-status-message--success {
  border-bottom: 4px solid #00b8b0;
}

.c-status-message--warning .c-status-message__icon {
  color: #edbc53;
}

.c-status-message--boxed.c-status-message--warning {
  border-bottom: 4px solid #edbc53;
}

/**
 * Popup
 * Default branding for component
 */
.js .c-popup {
  position: absolute;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  z-index: 100;
  padding: 16px;
  border: 1px solid #222222;
  box-shadow: 0 0 5px 0 rgba(127.5, 127.5, 127.5, 0.1);
  width: auto;
  border-radius: 2px;
  background-color: #fff;
}
.js .c-popup__close {
  position: absolute;
  display: block;
  top: 16px;
  right: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.29679575 12.2772478c-.39658757.3965876-.39438847 1.0328109-.00062148 1.4265779.39651227.3965123 1.03246768.3934888 1.42657791-.0006214l4.27724782-4.27724787 4.2772478 4.27724787c.3965876.3965875 1.0328109.3943884 1.4265779.0006214.3965123-.3965122.3934888-1.0324677-.0006214-1.4265779l-4.27724787-4.2772478 4.27724787-4.27724782c.3965875-.39658757.3943884-1.03281091.0006214-1.42657791-.3965122-.39651226-1.0324677-.39348875-1.4265779.00062148l-4.2772478 4.27724782-4.27724782-4.27724782c-.39658757-.39658757-1.03281091-.39438847-1.42657791-.00062148-.39651226.39651227-.39348875 1.03246768.00062148 1.42657791l4.27724782 4.27724782z' fill='%23666' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat;
  border: none;
  padding-right: 16px;
}
.js .c-popup__close-text {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.js .c-popup__arrow {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-top: 1px solid #222222;
  border-left: 1px solid #222222;
}
.js .c-popup__arrow--above {
  bottom: -11px;
  transform: rotate(225deg);
}
.js .c-popup__arrow--below {
  top: -11px;
  transform: rotate(45deg);
}

/**
 * Card
 * Default branding for component
 */
.eds-c-card {
  position: relative;
  text-decoration: none;
  display: flex;
  color: #222222;
}
.eds-c-card.eds-c-card--stretch-height {
  height: 100%;
}
.eds-c-card:focus {
  border-radius: 8px;
}

.eds-c-card__container {
  font-size: 0.875rem;
  min-width: 0;
  position: relative;
  overflow: hidden;
  font-family: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  flex: 1;
}
.eds-c-card__container img {
  width: 100%;
  height: inherit;
  object-fit: cover;
}
@supports (aspect-ratio: 1/1) {
  .eds-c-card__container img {
    padding-bottom: 0;
    aspect-ratio: var(--eds-c-card--image-aspect-ratio, 16/9);
  }
}
.eds-c-card__container.eds-c-card__container--image, .eds-c-card__container.eds-c-card__container--no-image {
  background-color: #f0f7fc;
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--image:hover, .eds-c-card__container.eds-c-card__container--no-image:hover {
  background-color: #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--icon {
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--icon .eds-c-card__inner {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1 0 100%;
  gap: 16px;
  border: 1px solid #c5e0f4;
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--icon .eds-c-card__icon-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f7fc;
  padding: 16px;
  margin-right: 16px;
  color: #0088cc;
}
.eds-c-card__container.eds-c-card__container--icon:hover {
  background-color: #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--icon:hover .eds-c-card__icon-container {
  background-color: #ffffff;
}
.eds-c-card__container.eds-c-card__container--metadata {
  flex-direction: column;
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--metadata .eds-c-card__inner {
  background-color: #f0f7fc;
}
.eds-c-card__container.eds-c-card__container--metadata .eds-c-card__inner--white-bg {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--metadata:hover .eds-c-card__inner {
  background-color: #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--metadata:hover .eds-c-card__metadata::before {
  border-top: 1px solid #ffffff;
}
.eds-c-card__container.eds-c-card__container--highlight {
  border-top: 10px solid #0088cc;
}
.eds-c-card__container.eds-c-card__container--highlight .eds-c-card__inner--white-bg {
  border-radius: unset;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid #c5e0f4;
  border-right: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
}

.eds-c-card__body {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
  padding: 16px;
}

.eds-c-card__content {
  font-weight: 400;
}

.eds-c-card__label {
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.eds-c-card__title {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 8px;
  max-width: 650px;
}

.eds-c-card__title > a {
  text-decoration: none;
  color: #222222;
}
.eds-c-card__title > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.eds-c-card__title > a:visited {
  color: #222222;
}

.eds-c-card__title--small {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

[lang=de] .eds-c-card__title {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.eds-c-card__summary {
  line-height: 1.5;
  max-width: 650px;
}
.eds-c-card__summary.eds-c-card__summary--truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.eds-c-card__image {
  flex: 0 0 auto;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}

.eds-c-card__icon {
  fill: #ffffff;
  width: 32px;
  height: 32px;
}

.eds-c-card__metadata {
  padding: 0 16px 16px 16px;
}
.eds-c-card__metadata::before {
  content: "";
  display: block;
  border-top: 1px solid #c5e0f4;
}
.eds-c-card__metadata .eds-c-card__metadata-list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 16px;
}
.eds-c-card__metadata .eds-c-card__metadata-list-description {
  font-weight: 700;
}
.eds-c-card__metadata .eds-c-card__metadata-list-description:not(:last-child) {
  margin-bottom: 16px;
}

.eds-c-button {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: relative;
  text-decoration: none;
  text-align: center;
  width: 100%;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s;
  border-radius: 32px;
  font-weight: 700;
}
.eds-c-button span {
  vertical-align: middle;
}
.eds-c-button svg {
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
}
.eds-c-button svg:first-child {
  margin-right: 8px;
}
.eds-c-button svg:last-child {
  margin-left: 8px;
}

@media only screen and (min-width: 480px) {
  .eds-c-button {
    width: auto;
  }
}
.eds-c-button--primary {
  color: #ffffff;
  background-color: #025e8d;
  background-image: none;
  border: 2px solid transparent;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--primary svg {
  fill: currentColor;
}
.eds-c-button--primary:visited {
  color: #ffffff;
}
.eds-c-button--primary:hover {
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--primary:focus {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 2px #025e8d;
  text-decoration: none;
}
.eds-c-button--primary:active {
  border: 2px solid #01324b;
  box-shadow: none;
  text-decoration: none;
  color: #01324b;
  background-color: #ffffff;
}
.eds-c-button--primary:hover, .eds-c-button--primary:focus {
  color: #025e8d;
  background-color: #ffffff;
  background-image: none;
}
.eds-c-button--primary:hover svg path, .eds-c-button--primary:focus svg path {
  fill: #025e8d;
}

.eds-c-button--secondary {
  color: #025e8d;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary svg {
  fill: currentColor;
}
.eds-c-button--secondary:visited {
  color: #025e8d;
}
.eds-c-button--secondary:hover {
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary:focus {
  border: 2px solid #ffffff;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary:active {
  border: 2px solid #01324b;
  box-shadow: none;
  text-decoration: none;
  color: #ffffff;
  background-color: #01324b;
}
.eds-c-button--secondary:hover, .eds-c-button--secondary:focus {
  color: #ffffff;
  background-color: #025e8d;
  background-image: none;
}
.eds-c-button--secondary:hover svg path, .eds-c-button--secondary:focus svg path {
  fill: #ffffff;
}

.eds-c-button--tertiary {
  color: #025e8d;
  background-color: #f0f7fc;
  background-image: none;
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
.eds-c-button--tertiary span {
  vertical-align: baseline;
}
.eds-c-button--tertiary svg {
  fill: currentColor;
}
.eds-c-button--tertiary:visited {
  color: #025e8d;
}
.eds-c-button--tertiary:hover {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.eds-c-button--tertiary:focus {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
}
.eds-c-button--tertiary:active {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  color: #01324b;
  background-color: #f0f7fc;
}
.eds-c-button--tertiary:hover, .eds-c-button--tertiary:focus {
  color: #025e8d;
  background-color: #f0f7fc;
  background-image: none;
}
.eds-c-button--tertiary:hover svg path, .eds-c-button--tertiary:focus svg path {
  fill: #025e8d;
}

.eds-c-button--small {
  font-size: 0.875rem;
  padding: 0.25rem 1rem;
}
.eds-c-button--small svg {
  width: 1rem;
  height: 1rem;
}

.eds-c-header {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  background-color: #ffffff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 1rem;
  line-height: 1.5;
}

.eds-c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}

.eds-c-header__nav {
  border-top: 2px solid #c5e0f4;
  padding-top: 4px;
  position: relative;
}

.eds-c-header__nav-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.eds-c-header__nav-container > *:not(:last-child) {
  margin-right: 32px;
}

.eds-c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}

.eds-c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-weight: bold;
  padding: 8px;
}
.eds-c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #c5e0f4;
}

.eds-c-header__item {
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .eds-c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .eds-c-header__item--menu:first-child + * {
    margin-block-start: 0;
  }
}

.eds-c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.eds-c-header__item--inline-links:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}

.eds-c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #c5e0f4;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}

.eds-c-header__brand {
  padding: 16px 8px;
}
.eds-c-header__brand a {
  display: block;
  line-height: 1;
  text-decoration: none;
}
.eds-c-header__brand img {
  height: 1.5rem;
  width: auto;
}

.eds-c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: bold;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.eds-c-header__link:visited {
  color: inherit;
}
.eds-c-header__link:hover {
  color: inherit;
  text-decoration-color: inherit;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover {
  text-decoration-color: transparent;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__link--static {
  flex: 0 0 auto;
}

.eds-c-header__link.is-open {
  color: #025e8d;
}
.eds-c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 1.5rem;
}
.eds-c-header__icon + * {
  margin-left: 8px;
}

.eds-c-header__expander {
  background-color: #f0f7fc;
}

.eds-c-header__search {
  display: block;
  padding: 24px 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__search {
    max-width: 70%;
  }
}

.eds-c-header__search-container {
  position: relative;
}

.eds-c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  margin-bottom: 8px;
}

.eds-c-header__search-input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: white;
}

.eds-c-header__search-button {
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}

.has-tethered.eds-c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}

@media only screen and (min-width: 768px) {
  .has-tethered.eds-c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .has-tethered.eds-c-header__expander--menu:first-child + * {
    margin-block-start: 0;
  }
}

.has-tethered.eds-c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}

.has-tethered .eds-c-header__heading {
  display: none;
  visibility: hidden;
}
.has-tethered .eds-c-header__heading:first-child + * {
  margin-block-start: 0;
}
.has-tethered .eds-c-header__search {
  margin: auto;
}

.eds-c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 16px 16px 0;
}

.eds-c-footer {
  font-size: 1rem;
  background-color: #01324b;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #ffffff;
  line-height: 1.4;
}

.eds-c-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media print {
  .eds-c-footer__container {
    display: none;
  }
}

.eds-c-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}
.eds-c-footer__links li {
  display: inline-block;
}
.eds-c-footer__links li:not(:last-child) {
  margin-right: 24px;
}

.eds-c-footer__user {
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 24px;
}

.eds-c-footer__user-info {
  margin-bottom: 16px;
  margin-top: 0;
  line-height: 1.4;
}

.eds-c-footer__legal {
  color: #ffffff;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 0;
}

.eds-c-footer__link {
  line-height: 1.4;
  color: inherit;
}
.eds-c-footer__link.visited, .eds-c-footer__link:visited {
  color: inherit;
}
.eds-c-footer__link.hover, .eds-c-footer__link:hover {
  color: inherit;
}

.eds-c-footer__link > img {
  vertical-align: middle;
}

button.eds-c-footer__link {
  border: none;
  background: none;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: inherit;
  padding: 0;
  text-align: left;
}

.eds-c-footer__button-text {
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: #01324b;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.eds-c-footer__button-text.active, .eds-c-footer__button-text:active {
  color: #025e8d;
}
.eds-c-footer__button-text.focus, .eds-c-footer__button-text:focus {
  outline: 4px solid #0088cc;
}
.eds-c-footer__button-text {
  color: inherit;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: inherit;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: inherit;
}

.eds-c-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-footer__grid {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .eds-c-footer__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }
}

.eds-c-footer__group {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .eds-c-footer__group {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .eds-c-footer__group {
    padding-right: 0;
    max-width: none;
    margin-bottom: 0;
  }
}

.eds-c-footer__group--separator {
  border-bottom: 2px solid #ffffff;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.eds-c-footer__heading {
  color: #ffffff;
  margin-bottom: 16px;
  font-weight: bold;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.eds-c-footer__heading--visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.eds-c-footer__item:not(:last-child) {
  margin-bottom: 16px;
}

.eds-c-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  padding: 32px 0;
  line-height: 1.4;
  gap: 16px 0;
}

@media only screen and (min-width: 480px) {
  .eds-c-pagination {
    padding: 32px 16px;
  }
}
.eds-c-pagination__item {
  margin-right: 8px;
}

.eds-c-pagination__item--prev {
  margin-right: 16px;
}
.eds-c-pagination__item--prev .eds-c-pagination__link {
  padding: 16px 8px;
}

.eds-c-pagination__item--next {
  margin-left: 8px;
}
.eds-c-pagination__item--next .eds-c-pagination__link {
  padding: 16px 8px;
}

.eds-c-pagination__item:last-child {
  margin-right: 0;
}

.eds-c-pagination__link {
  align-items: center;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  margin: 0;
  position: relative;
  padding: 16px 24px;
  text-align: center;
  transition: all 0.2s;
  color: #222222;
}

.eds-c-pagination__link:visited {
  color: #222222;
}

.eds-c-pagination__link--disabled {
  color: #555555;
  border-color: #555555;
  cursor: default;
}

.eds-c-pagination__link--active {
  color: #ffffff;
  background-color: #01324b;
  background-image: none;
  border-radius: 8px;
}

.eds-c-pagination__link--active:hover,
.eds-c-pagination__link--active:focus,
.eds-c-pagination__link--active:visited {
  color: #ffffff;
}

.eds-c-pagination__link-container {
  display: flex;
  align-items: center;
}

.eds-c-pagination__icon {
  fill: #222222;
  width: 1.5rem;
  height: 1.5rem;
}

.eds-c-pagination__icon--disabled {
  fill: #555555;
}

.eds-c-pagination__visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.c-breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-size: min(max(0.875rem, 2vw), 1rem);
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}
.c-breadcrumbs > li {
  display: inline;
}

.c-breadcrumbs__link {
  color: #025e8d;
}
.c-breadcrumbs__link.visited, .c-breadcrumbs__link:visited, .c-breadcrumbs__link.hover, .c-breadcrumbs__link:hover {
  color: #025e8d;
}

svg.c-breadcrumbs__chevron {
  margin: 0 0.25rem;
  fill: #333;
  width: 10px;
  height: 10px;
}

.c-breadcrumbs--contrast {
  color: #fff;
}
.c-breadcrumbs--contrast .c-breadcrumbs__link {
  color: #fff;
}
.c-breadcrumbs--contrast .c-breadcrumbs__link.visited, .c-breadcrumbs--contrast .c-breadcrumbs__link:visited, .c-breadcrumbs--contrast .c-breadcrumbs__link.hover, .c-breadcrumbs--contrast .c-breadcrumbs__link:hover {
  color: #fff;
}
.c-breadcrumbs--contrast svg.c-breadcrumbs__chevron {
  fill: #fff;
}

@media only screen and (max-width: 479px) {
  .c-breadcrumbs .c-breadcrumbs__item {
    display: none;
  }
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(1),
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(2) {
    display: inline;
  }
}
/**
 * @springernature/eds/skip-link
 * Default skin settings
 *
 */
/**
 * @springernature/eds/skip-link
 * A full width link which is hidden for mouse users
 *
 */
.c-skip-link {
  background: #01324b;
  color: white;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 8px;
  position: absolute;
  width: 100%;
  inset: 0;
  bottom: auto;
  z-index: 9999;
  transform: translateY(-100%);
}
@media (prefers-reduced-motion: reduce) {
  .c-skip-link {
    transition: top 0.3s ease-in-out;
  }
}
@media print {
  .c-skip-link {
    display: none;
  }
}
.c-skip-link:link, .c-skip-link:active, .c-skip-link:visited, .c-skip-link:hover {
  color: white;
}
.c-skip-link:focus {
  transform: translateY(0%);
}

/**
 * @springernature/elements/layout-with-sidebar
 * Default skin settings
 *
 */
.l-with-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.l-with-sidebar > * {
  margin: 0;
}

.l-with-sidebar__sidebar {
  flex-grow: 1;
  flex-basis: 400px; /* fallback static value for IE */
  flex-basis: var(--with-sidebar--basis, 400px);
}

.l-with-sidebar > :not(.l-with-sidebar__sidebar) {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 53%; /* fallback static value for IE */
  min-width: var(--with-sidebar--min, 53%);
}

.l-with-sidebar > :first-child {
  padding-right: 4rem; /* gap for IE11 (so desktop) */
}

@supports (gap: 1em) {
  .l-with-sidebar > :first-child {
    padding-right: 0;
  }
  .l-with-sidebar {
    gap: var(--with-sidebar--gap, 4rem);
  }
}
.c-header {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  line-height: 1.4;
  background-color: #fff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 16px;
}

.c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}

.c-header__nav {
  border-top: 2px solid #cedbe0;
  padding-top: 4px;
  position: relative;
}

.c-header__nav-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.c-header__nav-container > *:not(:last-child) {
  margin-right: 32px;
}

.c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}

.c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-weight: bold;
  padding: 8px;
}
.c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #cedbe0;
}

.c-header__item {
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .c-header__item--menu:first-child + * {
    margin-block-start: 0;
  }
}

.c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.c-header__item--inline-links:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 768px) {
  .c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}

.c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #cedbe0;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}

.c-header__brand a {
  display: block;
  line-height: 1;
  padding: 16px 8px;
  text-decoration: none;
}
.c-header__brand img {
  height: 24px;
  width: auto;
}

.c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: bold;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.c-header__link:visited {
  color: inherit;
}
.c-header__link:hover {
  color: inherit;
  text-decoration-color: inherit;
}

.c-header__link--static {
  flex: 0 0 auto;
}

.c-header__link.is-open {
  color: #025e8d;
}
.c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 24px;
}
.c-header__icon + * {
  margin-left: 8px;
}

.c-header__expander {
  background-color: #ebf1f5;
}

.c-header__search {
  padding: 24px 0;
}
@media only screen and (min-width: 768px) {
  .c-header__search {
    max-width: 70%;
  }
}

.c-header__search-container {
  position: relative;
}

.c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  margin-bottom: 8px;
}

.c-header__search-input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: white;
}

.c-header__search-button {
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}

.has-tethered.c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}

@media only screen and (min-width: 768px) {
  .has-tethered.c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .has-tethered.c-header__expander--menu:first-child + * {
    margin-block-start: 0;
  }
}

.has-tethered.c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}

.has-tethered .c-header__heading {
  display: none;
  visibility: hidden;
}
.has-tethered .c-header__heading:first-child + * {
  margin-block-start: 0;
}
.has-tethered .c-header__search {
  margin: auto;
}

.c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 16px 16px 0;
}

.c-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  border-bottom: 2px solid #999999;
  padding-bottom: 4px;
  font-size: 0.875rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-list-description {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  line-height: 1.8;
  margin-bottom: 0;
  width: 100%;
}

.c-list-description__item {
  margin-bottom: 16px;
}
.c-list-description__item:last-child {
  margin-bottom: 0;
}

.c-list-description__term {
  font-weight: 700;
  margin-bottom: 0;
}

.c-list-description__details {
  display: block;
}

.c-list-description__details p {
  display: block;
  margin-bottom: 0;
}
.c-list-description__details p:last-child {
  margin-bottom: 0;
}

.c-list-columned {
  column-gap: 24px;
}
@media only screen and (min-width: 768px) {
  .c-list-columned {
    column-count: 2;
  }
}
@media only screen and (min-width: 876px) {
  .c-list-columned {
    column-count: 3;
  }
}

.c-list-group {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.c-list-group__item > a.c-list-group__link--bold {
  font-weight: 700;
}

.c-list-group--xs .c-list-group__item {
  padding: 4px 0;
}

.c-list-group--sm .c-list-group__item {
  padding: 8px 0;
}

.c-list-group--md .c-list-group__item {
  padding: 16px 0;
}

.c-list-group--lg .c-list-group__item {
  padding: 24px 0;
}

.c-list-group--bordered .c-list-group__item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.c-list-group--flush .c-list-group__item:first-child {
  padding-top: 0;
}

.c-list-group--flush .c-list-group__item:last-child {
  padding-bottom: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide) {
  list-style-type: none;
  list-style-position: initial;
  padding: 0;
  margin-bottom: 48px;
}

.c-list-bullets ul:not(.c-list-bullets--hide):has(+ .u-mt-24) {
  margin-bottom: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li {
  position: relative;
  padding-left: 24px;
  line-height: 1.8;
  margin-bottom: 0.5em;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li > ul {
  margin-top: 0.5em;
  margin-bottom: 0;
}

.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide),
.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide) li {
  margin-bottom: 0;
}

.c-list-bullets--no-spacing ul:not(.c-list-bullets--hide) li > ul {
  margin-top: 0;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li::before {
  position: absolute;
  content: "";
  left: 0;
  top: calc(0.9em - 5px);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' fill='%233a9bd6'%3E%3Ccircle cx='50' cy='50' r='50'/%3E%3C/svg%3E");
  width: 10px;
  height: 10px;
}

.c-list-bullets ul:not(.c-list-bullets--hide) li > ul > li:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='49.4' cy='49.4' r='44.4' stroke='%233a9bd6' stroke-width='10' fill='none'/%3E%3C/svg%3E");
}

.c-list-bullets ul:not(.c-list-bullets--hide) li ol {
  margin: 1em 0 16px 0;
  padding-inline-start: 1em;
}
.c-list-bullets ul:not(.c-list-bullets--hide) li ol li {
  padding-left: 0.5em;
}
.c-list-bullets ul:not(.c-list-bullets--hide) li ol li:before {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .c-list-bullets ul:not(.c-list-bullets--hide) li ol {
    padding-inline-start: 2em;
  }
}

/**
 * Meta
 * Default branding for component
 */
.c-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  color: #000000;
  line-height: 1.5;
}

.c-meta--large {
  font-size: 1.25rem;
}
.c-meta--large .c-meta__item {
  margin-bottom: 20px;
}

.c-meta__item:not(:last-child) {
  border-right: 2px solid #999999;
  padding-right: 8px;
  margin-right: 8px;
  line-height: 1;
  border-color: #c5e0f4;
}

.c-meta__item--spacing:not(:last-child) {
  margin-bottom: 4px;
}

@media only screen and (max-width: 767px) {
  .c-meta__item--block-sm-max {
    display: block;
  }
  .c-meta__item--block-sm-max:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .c-meta__item--block-at-lg {
    display: block;
  }
  .c-meta__item--block-at-lg:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.c-meta__type {
  text-transform: none;
  font-weight: 700;
}

.c-meta__link {
  color: #222222;
  text-decoration: underline;
}

.c-meta__link:visited {
  color: #222222;
}

.app-masthead__colour-1 {
  --background-color: rgb(255, 21, 0);
}

.app-masthead__colour-2 {
  --background-color: rgb(255, 64, 0);
}

.app-masthead__colour-3 {
  --background-color: rgb(255, 106, 0);
}

.app-masthead__colour-4 {
  --background-color: rgb(255, 149, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-5 {
  --background-color: rgb(255, 191, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-6 {
  --background-color: rgb(255, 234, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-7 {
  --background-color: rgb(234, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-8 {
  --background-color: rgb(191, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-9 {
  --background-color: rgb(149, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-10 {
  --background-color: rgb(106, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-11 {
  --background-color: rgb(43, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-12 {
  --background-color: rgb(0, 255, 43);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-13 {
  --background-color: rgb(0, 255, 106);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-14 {
  --background-color: rgb(0, 255, 149);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-15 {
  --background-color: rgb(0, 255, 191);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-16 {
  --background-color: rgb(0, 255, 234);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-17 {
  --background-color: rgb(0, 234, 255);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-18 {
  --background-color: rgb(0, 191, 255);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-19 {
  --background-color: rgb(0, 149, 255);
}

.app-masthead__colour-20 {
  --background-color: rgb(0, 106, 255);
}

.app-masthead__colour-21 {
  --background-color: rgb(0, 21, 255);
}

.app-masthead__colour-22 {
  --background-color: rgb(121, 77, 255);
}

.app-masthead__colour-23 {
  --background-color: rgb(151, 77, 255);
}

.app-masthead__colour-24 {
  --background-color: rgb(170, 51, 255);
}

.app-masthead__colour-25 {
  --background-color: rgb(198, 26, 255);
}

.app-masthead__colour-26 {
  --background-color: rgb(255, 0, 234);
}

.app-masthead__colour-27 {
  --background-color: rgb(255, 0, 191);
}

.app-masthead__colour-28 {
  --background-color: rgb(255, 0, 149);
}

.app-masthead__colour-29 {
  --background-color: rgb(255, 0, 106);
}

.app-masthead__colour-30 {
  --background-color: rgb(255, 0, 64);
}

.app-masthead__colour-31 {
  --background-color: rgb(255, 0, 21);
}

.app-masthead--pastel {
  --gradient-light: rgba(255, 255, 255, 0.9);
  --gradient-dark: rgba(255, 255, 255, 0.75);
  --masthead-color: #000;
}

.app-masthead__colour-1--pastel {
  --background-color: rgb(255, 138, 128);
}

.app-masthead__colour-2--pastel {
  --background-color: rgb(255, 159, 128);
}

.app-masthead__colour-3--pastel {
  --background-color: rgb(255, 181, 128);
}

.app-masthead__colour-4--pastel {
  --background-color: rgb(255, 191, 102);
}

.app-masthead__colour-5--pastel {
  --background-color: rgb(255, 217, 102);
}

.app-masthead__colour-6--pastel {
  --background-color: rgb(255, 242, 102);
}

.app-masthead__colour-7--pastel {
  --background-color: rgb(242, 255, 102);
}

.app-masthead__colour-8--pastel {
  --background-color: rgb(223, 255, 128);
}

.app-masthead__colour-9--pastel {
  --background-color: rgb(202, 255, 128);
}

.app-masthead__colour-10--pastel {
  --background-color: rgb(181, 255, 128);
}

.app-masthead__colour-11--pastel {
  --background-color: rgb(170, 255, 153);
}

.app-masthead__colour-12--pastel {
  --background-color: rgb(153, 255, 170);
}

.app-masthead__colour-13--pastel {
  --background-color: rgb(153, 255, 195);
}

.app-masthead__colour-14--pastel {
  --background-color: rgb(153, 255, 204);
}

.app-masthead__colour-15--pastel {
  --background-color: rgb(153, 255, 229);
}

.app-masthead__colour-16--pastel {
  --background-color: rgb(153, 255, 246);
}

.app-masthead__colour-17--pastel {
  --background-color: rgb(153, 246, 255);
}

.app-masthead__colour-18--pastel {
  --background-color: rgb(153, 229, 255);
}

.app-masthead__colour-19--pastel {
  --background-color: rgb(153, 212, 255);
}

.app-masthead__colour-20--pastel {
  --background-color: rgb(153, 195, 255);
}

.app-masthead__colour-21--pastel {
  --background-color: rgb(178, 191, 255);
}

.app-masthead__colour-22--pastel {
  --background-color: rgb(178, 178, 255);
}

.app-masthead__colour-23--pastel {
  --background-color: rgb(198, 178, 255);
}

.app-masthead__colour-24--pastel {
  --background-color: rgb(210, 178, 255);
}

.app-masthead__colour-25--pastel {
  --background-color: rgb(223, 178, 255);
}

.app-masthead__colour-26--pastel {
  --background-color: rgb(236, 178, 255);
}

.app-masthead__colour-27--pastel {
  --background-color: rgb(255, 178, 249);
}

.app-masthead__colour-28--pastel {
  --background-color: rgb(255, 153, 229);
}

.app-masthead__colour-29--pastel {
  --background-color: rgb(255, 153, 212);
}

.app-masthead__colour-30--pastel {
  --background-color: rgb(255, 153, 195);
}

.app-masthead__colour-31--pastel {
  --background-color: rgb(255, 153, 161);
}

.app-masthead {
  position: relative;
  background: #0070a8;
  background: var(--background-color, #0070a8);
}

.app-masthead::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(circle at top right, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
}

.app-masthead--pastel .app-masthead {
  background: rgb(102, 170, 204);
  background: var(--background-color, rgb(102, 170, 204));
}

.app-masthead.app-masthead--disable-journal-colour::after {
  background: unset;
}

@media only screen and (max-width: 479px) {
  .app-masthead::after {
    background: linear-gradient(225deg, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
  }
}
.app-masthead__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  position: relative;
  z-index: 1;
  color: var(--masthead-color, #fff);
}

.app-card-open {
  position: relative;
  border-bottom: 1px solid transparent;
  background-color: #ffffff;
  padding: 24px 0;
  border-top: 1px solid #c5e0f4;
}
.app-card-open a:not(.app-card-open__link) {
  position: relative;
}

.app-card-open--last {
  border-bottom: 1px solid #c5e0f4;
}

.app-card-open__main {
  flex: 1 1 auto;
  word-break: break-word;
  align-self: baseline;
}

.app-card-open__side {
  position: absolute;
  right: 0;
  top: 24px;
  overflow: hidden;
}

.app-card-open--portrait-ratio .app-card-open__side {
  overflow: auto;
}

.app-card-open img {
  border: 1px solid #cfd8dc;
  border-radius: 12px;
  width: 112px;
  aspect-ratio: 16 / 9;
}

.app-card-open.app-card-open--portrait-ratio img {
  border-radius: 0;
  width: 45px;
  aspect-ratio: auto;
}

.app-card-open__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1rem;
}

.app-card-open__subheading::before {
  content: "";
  display: block;
  padding-top: 8px;
}

.app-card-open__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.app-card-open__heading a {
  line-height: 1.5;
}

.app-card-open__description {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 16px;
}
@media only screen and (min-width: 480px) {
  .app-card-open__description--larger {
    font-size: 1rem;
  }
}

.app-card-open--has-image .app-card-open__heading {
  max-width: calc(100vw - 112px - 16px - 16px - 16px);
  min-height: 58px;
  padding-right: 8px;
}

.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__heading,
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__description {
  max-width: calc(100vw - 45px - 16px - 16px - 16px);
}
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__heading {
  min-height: auto;
}
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__description {
  padding-right: 8px;
}

.app-card-open__collections-list {
  margin-top: 8px;
}

.app-card-open__heading a {
  color: #000000;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.app-card-open__meta {
  margin-top: 4px;
}

.app-card-open__authors,
.app-card-open__journal {
  display: none;
  font-size: 0.875rem;
  line-height: 1.5;
}

.app-card-open__authors a,
.app-card-open__journal a {
  color: #222222;
}

.app-card-open--compact.app-card-open {
  padding: 16px 0;
}

.app-card-open--full-width .app-card-open__main,
.app-card-open--compact .app-card-open__main {
  margin-right: 0;
}

.app-card-open--compact .app-card-open__heading {
  margin-bottom: 8px;
  font-size: 1rem;
}

.app-card-open__text-container {
  font-size: 0;
}

.app-card-open__metadata-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  flex-direction: column;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list {
    flex-direction: row;
  }
}

.app-card-open__metadata-list div {
  display: flex;
  border-top: 1px solid #c5e0f4;
  min-width: 124px;
  padding: 8px 0;
  justify-content: space-between;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list div {
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 16px;
    border-top: 0;
  }
  .app-card-open__metadata-list div:first-child {
    padding-left: 0;
  }
}

@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list div + div {
    border-left: 1px solid #c5e0f4;
  }
}

.app-card-open__metadata-list-term {
  font-size: 0.875rem;
}

.app-card-open__metadata-list-description {
  font-weight: 700;
  font-size: 0.875rem;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list-description {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 480px) {
  .app-card-open {
    display: flex;
    justify-content: space-between;
  }
  .app-card-open__main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 192px;
  }
  .app-card-open--has-image .app-card-open__main {
    margin-right: 16px;
  }
  .app-card-open__side {
    position: initial;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .app-card-open img {
    width: 192px;
  }
  .app-card-open--compact img {
    width: 112px;
  }
  .app-card-open__heading {
    font-size: 1.125rem;
  }
  .app-card-open--has-image .app-card-open__heading {
    max-width: initial;
    min-height: initial;
  }
  .app-card-open.app-card-open--portrait-ratio img {
    width: auto;
  }
  .app-card-open__heading,
  .app-card-open__description {
    padding-right: 0;
  }
  .app-card-open__authors,
  .app-card-open__journal {
    display: inline;
  }
}
.app-card-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #025e8d;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 16px 0;
  border-top: 1px solid #c5e0f4;
}

.app-card-service:last-of-type {
  border-bottom: 1px solid #c5e0f4;
}

.app-card-service__link:hover,
.app-card-service__link:focus {
  color: #025e8d;
}

.app-card-service__link-text {
  font-weight: 700;
  line-height: 1.5;
}

.app-card-service__link-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  fill: #025e8d;
}

.app-card-service__icon-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f7fc;
  padding: 14px;
}

.app-card-service__icon {
  fill: #0088cc;
  width: 32px;
  height: 32px;
}

.app-card-service__description {
  color: #222222;
  margin-top: 8px;
}

.MathJax_SVG svg {
  width: auto;
  height: auto;
}

.MathJax_SVG svg {
  max-width: 100%;
}

#MathJax_Zoom svg {
  max-width: none;
}

.u-clearfix::before, .u-clearfix::after {
  content: "";
  display: table;
}
.u-clearfix::after {
  clear: both;
}

.u-clear-both {
  clear: both;
}

.u-clear-left {
  clear: left;
}

.u-clear-right {
  clear: right;
}

.u-clear-float {
  float: none;
}

.u-color-open-access {
  color: #b74616;
}

.u-color-inherit {
  color: inherit;
}

.u-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.u-display-block {
  display: block;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block {
  display: inline-block;
}

.u-display-flex {
  display: flex;
  width: 100%;
}

.u-display-inline-flex {
  display: inline-flex;
}

.u-display-grid {
  display: grid;
}

.u-display-table {
  display: table;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-left {
  justify-content: left;
}

.u-flex-grow {
  flex: 1 0 auto;
}

.u-flex-grow-shrink {
  flex: 1 1 auto;
}

.u-flex-shrink {
  flex: 0 1 auto;
}

.u-flex-static {
  flex: 0 0 auto;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-float-right {
  float: right;
}

.u-float-left {
  float: left;
}

.u-display-none {
  display: none;
}

/* hide from both screenreaders and browsers */
.u-hide,
.js .u-js-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + *,
.js .u-js-hide:first-child + * {
  margin-block-start: 0;
}

/* show to both screenreaders and browsers */
.u-show,
.js .u-js-show {
  display: block;
  visibility: visible;
}

.u-show-inline,
.js .u-js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.u-visually-hidden,
.js .u-js-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.u-visually-hidden--off,
.js .u-js-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

/* make invisible but retain dimensions */
.u-invisible {
  visibility: hidden;
}

/* hide only the text, keep element visible */
.u-hide-text,
.js .u-js-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}

/* hiding overflow */
.u-hide-overflow {
  overflow: hidden;
}

.u-hide-sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media print {
  .u-hide-print {
    display: none;
  }
}
/**
 * media-query hiding
 * intentionally avoids 'max' and 'range' to prevent bloat
 */
@media only screen and (min-width: 480px) {
  .u-hide-at-xs,
  .js .u-js-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + *,
  .js .u-js-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xs,
  .js .u-js-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-sm,
  .js .u-js-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + *,
  .js .u-js-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-sm,
  .js .u-js-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 876px) {
  .u-hide-at-md,
  .js .u-js-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + *,
  .js .u-js-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-md,
  .js .u-js-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg,
  .js .u-js-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + *,
  .js .u-js-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-lg,
  .js .u-js-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl,
  .js .u-js-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + *,
  .js .u-js-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xl,
  .js .u-js-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}

.u-full-height {
  height: 100%;
}

.u-vertical-align-baseline {
  vertical-align: baseline;
}

.u-vertical-align-top {
  vertical-align: top;
}

.u-vertical-align-bottom {
  vertical-align: bottom;
}

.u-vertical-align-middle {
  vertical-align: middle;
}

.u-link-text {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.u-link-text--large {
  font-size: 1.25rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.u-list-style-number {
  list-style-type: decimal;
}

.u-list-style-bullet {
  list-style-type: disc;
}

.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}

.u-list-style-none {
  list-style-type: none;
}

.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}

.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li {
  display: inline;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}

.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-visible {
  overflow: visible;
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.u-position-top {
  top: 0;
}

.u-position-left {
  left: 0;
}

.u-position-bottom {
  bottom: 0;
}

.u-position-right {
  right: 0;
}

.u-position-negative-right {
  right: 100%;
}

.u-scrollbar-thin {
  scrollbar-width: thin;
}

.u-ma-0 {
  margin: 0;
}

.u-ma-2 {
  margin: 2px;
}

.u-ma-4 {
  margin: 4px;
}

.u-ma-8 {
  margin: 8px;
}

.u-ma-16 {
  margin: 16px;
}

.u-ma-24 {
  margin: 24px;
}

.u-ma-32 {
  margin: 32px;
}

.u-ma-48 {
  margin: 48px;
}

.u-ma-64 {
  margin: 64px;
}

.u-ma-auto {
  margin: auto;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-2 {
  margin-top: 2px;
}

.u-mt-4 {
  margin-top: 4px;
}

.u-mt-8 {
  margin-top: 8px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-mt-24 {
  margin-top: 24px;
}

.u-mt-32 {
  margin-top: 32px;
}

.u-mt-48 {
  margin-top: 48px;
}

.u-mt-64 {
  margin-top: 64px;
}

.u-mt-auto {
  margin-top: auto;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mr-2 {
  margin-right: 2px;
}

.u-mr-4 {
  margin-right: 4px;
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-16 {
  margin-right: 16px;
}

.u-mr-24 {
  margin-right: 24px;
}

.u-mr-32 {
  margin-right: 32px;
}

.u-mr-48 {
  margin-right: 48px;
}

.u-mr-64 {
  margin-right: 64px;
}

.u-mr-auto {
  margin-right: auto;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-2 {
  margin-bottom: 2px;
}

.u-mb-4 {
  margin-bottom: 4px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-24 {
  margin-bottom: 24px;
}

.u-mb-32 {
  margin-bottom: 32px;
}

.u-mb-48 {
  margin-bottom: 48px;
}

.u-mb-64 {
  margin-bottom: 64px;
}

.u-mb-auto {
  margin-bottom: auto;
}

.u-ml-0 {
  margin-left: 0;
}

.u-ml-2 {
  margin-left: 2px;
}

.u-ml-4 {
  margin-left: 4px;
}

.u-ml-8 {
  margin-left: 8px;
}

.u-ml-16 {
  margin-left: 16px;
}

.u-ml-24 {
  margin-left: 24px;
}

.u-ml-32 {
  margin-left: 32px;
}

.u-ml-48 {
  margin-left: 48px;
}

.u-ml-64 {
  margin-left: 64px;
}

.u-ml-auto {
  margin-left: auto;
}

.u-pa-0 {
  padding: 0;
}

.u-pa-2 {
  padding: 2px;
}

.u-pa-4 {
  padding: 4px;
}

.u-pa-8 {
  padding: 8px;
}

.u-pa-16 {
  padding: 16px;
}

.u-pa-24 {
  padding: 24px;
}

.u-pa-32 {
  padding: 32px;
}

.u-pa-48 {
  padding: 48px;
}

.u-pa-64 {
  padding: 64px;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-2 {
  padding-top: 2px;
}

.u-pt-4 {
  padding-top: 4px;
}

.u-pt-8 {
  padding-top: 8px;
}

.u-pt-16 {
  padding-top: 16px;
}

.u-pt-24 {
  padding-top: 24px;
}

.u-pt-32 {
  padding-top: 32px;
}

.u-pt-48 {
  padding-top: 48px;
}

.u-pt-64 {
  padding-top: 64px;
}

.u-pr-0 {
  padding-right: 0;
}

.u-pr-2 {
  padding-right: 2px;
}

.u-pr-4 {
  padding-right: 4px;
}

.u-pr-8 {
  padding-right: 8px;
}

.u-pr-16 {
  padding-right: 16px;
}

.u-pr-24 {
  padding-right: 24px;
}

.u-pr-32 {
  padding-right: 32px;
}

.u-pr-48 {
  padding-right: 48px;
}

.u-pr-64 {
  padding-right: 64px;
}

.u-pb-0 {
  padding-bottom: 0;
}

.u-pb-2 {
  padding-bottom: 2px;
}

.u-pb-4 {
  padding-bottom: 4px;
}

.u-pb-8 {
  padding-bottom: 8px;
}

.u-pb-16 {
  padding-bottom: 16px;
}

.u-pb-24 {
  padding-bottom: 24px;
}

.u-pb-32 {
  padding-bottom: 32px;
}

.u-pb-48 {
  padding-bottom: 48px;
}

.u-pb-64 {
  padding-bottom: 64px;
}

.u-pl-0 {
  padding-left: 0;
}

.u-pl-2 {
  padding-left: 2px;
}

.u-pl-4 {
  padding-left: 4px;
}

.u-pl-8 {
  padding-left: 8px;
}

.u-pl-16 {
  padding-left: 16px;
}

.u-pl-24 {
  padding-left: 24px;
}

.u-pl-32 {
  padding-left: 32px;
}

.u-pl-48 {
  padding-left: 48px;
}

.u-pl-64 {
  padding-left: 64px;
}

.u-keyline {
  border-bottom: 1px solid #999999;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-keyline--thick {
  border-bottom: 2px solid #999999;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-shadow {
  border: 1px solid #000000;
  box-shadow: 5px 5px 5px #000000;
}

.u-button-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.u-monospace {
  font-family: monospace;
}

.u-sans-serif {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.u-serif {
  font-family: "Merriweather", serif;
}

.u-small-caps {
  font-size: 0.8em; /* scale based on containing text */
  text-transform: uppercase;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-text-xxs {
  font-size: 0.75rem;
}

.u-text-xs {
  font-size: 0.875rem;
}

.u-text-s {
  font-size: 1rem;
}

.u-text-default {
  font-size: 1.125rem;
}

.u-text-md {
  font-size: 1.25rem;
}

.u-text-l {
  font-size: 1.5rem;
}

.u-text-xl {
  font-size: 1.75rem;
}

.u-text-xxl {
  font-size: 2rem;
}

.u-text-hl {
  font-size: 2.1875rem;
}

.u-text-hxl {
  font-size: 2.25rem;
}

.u-meta-large {
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
}

.u-meta-medium {
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
}

.u-meta-small {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.u-upper {
  text-transform: uppercase;
}

.u-lower {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.u-unstyled {
  font-weight: normal;
  font-style: normal;
}

.u-h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

.u-h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.u-mbs-0 {
  margin-block-start: 0 !important;
}

/**
 * Style mixins
 *
 */
/**
 *	Settings
 *	Darwin specific article component configuration (overrides configuration from shared-article/10-settings)
*/
/**
 * Settings
 * Article component configuration
 */
/* Spacing defined by the 8-Point grid - https://spec.fm/specifics/8-pt-grid */
p {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

/**
 * Darwin Article Enhanced
 * Styles used across Darwin pages for browsers that pass CTM
 * `article-` = shared // `darwin-article` = darwin only
 */
/**
 * Article Head Styles
 * Article styles for browsers that pass CTM
 */
.c-article-header {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 40px;
}

.c-article-identifiers {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
  color: rgb(110.5, 110.5, 110.5);
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 8px;
}

.c-article-identifiers__item {
  border-right: 1px solid rgb(110.5, 110.5, 110.5);
  margin-right: 8px;
  padding-right: 8px;
  list-style: none;
}
.c-article-identifiers__item:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}

.c-article-title {
  font-size: 1.5rem;
  line-height: 1.25;
  margin: 0 0 16px;
}
@media only screen and (min-width: 876px) {
  .c-article-title {
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
.c-article-title__super {
  font-size: 1rem;
  line-height: 1.3;
  color: rgb(97.75, 97.75, 97.75);
  margin: 16px 0 0 0;
}

.c-article-author-list {
  width: 100%;
  margin: 0 8px 0 0;
  padding: 0;
  display: inline;
  list-style: none;
  font-size: 1rem;
}

.c-article-author-list__item {
  display: inline;
  padding-right: 0;
}

.c-article-author-list svg {
  margin-left: 4px;
}

.c-article-author-list__show-more {
  display: none;
  margin-right: 4px;
}

.js .c-article-author-list__item--hide,
.js .c-article-author-list__show-more,
.c-article-author-list__button {
  display: none;
}

.js .c-article-author-list--long .c-article-author-list__show-more,
.js .c-article-author-list--long + .c-article-author-list__button {
  display: inline;
}

@media only screen and (max-width: 767px) {
  .js .c-article-author-list__item--hide-small-screen {
    display: none;
  }
  .js .c-article-author-list--short .c-article-author-list__show-more,
  .js .c-article-author-list--short + .c-article-author-list__button {
    display: inline;
  }
}
/* handle list being expanded */
.js .c-article-author-list--expanded .c-article-author-list__show-more {
  display: none !important;
}

.js .c-article-author-list--expanded .c-article-author-list__item--hide-small-screen {
  display: inline !important;
}

.c-article-author-list__button,
.c-button-author-list {
  background: #ebf1f5;
  border: 4px solid #ebf1f5;
  border-radius: 20px;
  color: #666;
  font-size: 0.875rem;
  line-height: 1.4;
  padding: 2px 11px 2px 8px;
  text-decoration: none;
}
.c-article-author-list__button svg,
.c-button-author-list svg {
  margin: 1px 4px 0 0;
}
.c-article-author-list__button:hover,
.c-button-author-list:hover {
  background: #025e8d;
  border-color: transparent;
  color: white;
}

.c-article-info-details {
  font-size: 1rem;
  margin-bottom: 8px;
  margin-top: 16px;
}
.c-article-info-details__cite-as, .c-article-info-details__show-history {
  border-left: 1px solid rgb(110.5, 110.5, 110.5);
  margin-left: 8px;
  padding-left: 8px;
}

.c-article-info-details + .c-article-info-details {
  margin-top: 0;
}

.c-article-metrics-bar {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.3;
  font-size: 1rem;
}

.c-article-metrics-bar__wrapper {
  margin: 16px 0;
}

.c-article-metrics-bar__item {
  align-items: baseline;
  border-right: 1px solid rgb(110.5, 110.5, 110.5);
  margin-right: 8px;
}
.c-article-metrics-bar__item:last-child {
  border-right: 0;
}

.c-article-metrics-bar__count {
  font-weight: bold;
  margin: 0;
}

.c-article-metrics-bar__label {
  color: rgb(97.75, 97.75, 97.75);
  font-weight: normal;
  font-style: normal;
  margin: 0 10px 0 5px;
}

.c-article-metrics-bar__details {
  margin: 0;
}

/**
 * Article Enhanced
 * Article styles for browsers that pass CTM
 */
/* reset list styles */
.c-article-author-institutional-author,
.c-article-author-information__list,
.c-article-references,
.c-article-author-affiliation__list,
.c-article-author-authors-search,
.c-article-supplementary__list,
.c-article-further-reading__list,
.c-article-footnote--listed,
.c-article-references__nav,
.c-article-change-list,
.c-section-author-list {
  list-style: none;
  padding: 0;
}

.c-section-author-list {
  margin-bottom: 16px;
}

.c-section-author-list > li {
  display: inline;
  font-weight: bold;
}

.c-article-associated-content__container .c-article-associated-content__title,
.c-article-section__title {
  border-bottom: 2px solid rgb(212.5, 212.5, 212.5);
  font-size: 1.25rem;
  padding-bottom: 8px;
  margin: 0;
}
@media only screen and (min-width: 876px) {
  .c-article-associated-content__container .c-article-associated-content__title,
  .c-article-section__title {
    font-size: 1.5rem;
    line-height: 1.24;
  }
}

.c-article-associated-content__container .c-article-associated-content__title {
  margin-bottom: 8px;
}

.c-article-body p,
.c-article-body ul,
.c-article-body ol,
.c-article-body dl {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-references p,
.c-bibliographic-information__column p,
.c-bibliographic-information__column button {
  margin-bottom: 16px;
}

.c-article-section {
  clear: both;
}

.c-article-section__content {
  padding-top: 8px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1023px) {
  .c-article-section__content {
    padding-left: 0;
  }
}

.c-article-section__content--separator {
  border-top: 2px solid rgb(212.5, 212.5, 212.5);
  padding-top: 40px;
}

.c-article__pill-button {
  padding: 8px 16px 8px 20px;
  background-color: white;
  border: 4px solid green;
  border-radius: 20px;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.c-article__pill-button:hover {
  background-color: #025e8d;
  color: white;
  text-decoration: none;
}

.c-article__pill-button:focus {
  outline: 0;
  box-shadow: 0 0 0 3px yellow;
  text-decoration: none;
}

.c-article__pill-button:active,
.c-article__pill-button:hover {
  box-shadow: 0 0 0 0;
}

.c-article__pill-button svg {
  height: 0.8em;
  margin-left: 2px;
}

.c-article-section__illustration {
  font-size: 0.875rem;
  float: none;
  margin: 0 auto 24px;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .c-article-section__illustration-right {
    margin: 8px 0 16px 24px;
    float: right;
  }
}

.c-article__sub-heading {
  font-size: 1.125rem;
  font-weight: normal;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 1.3;
  color: #222222;
  margin: 24px 0 8px;
}
@media only screen and (min-width: 876px) {
  .c-article__sub-heading {
    font-size: 1.5rem;
    line-height: 1.24;
  }
}
.c-article__sub-heading--small {
  font-size: 1.125rem;
  font-weight: bold;
}

.c-article__sub-heading:first-child {
  margin-top: 0;
}

.c-article__sub-heading--divider::before {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  margin-top: 40px;
  margin-bottom: 16px;
  background: #222222;
}

.c-article-body .c-article-access-provider,
.c-notes {
  padding: 8px 16px;
  border: 1px solid rgb(212.5, 212.5, 212.5);
  border-left: none;
  border-right: none;
  margin: 24px 0;
}
.c-article-body .c-article-access-provider__text,
.c-notes__text {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 0;
  font-size: 1rem;
  color: #555555;
  text-align: center;
}

.c-article-change-list__item > ins {
  text-decoration: none;
}

.c-article-change-list__details,
.c-article-change-list__details > p:first-child {
  display: inline;
}

.c-article-references__heading {
  font-weight: bold;
  font-size: 1.05rem;
  margin: 24px 0 4px;
}

.c-article-references__item {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.c-article-references__item::before {
  content: attr(data-counter);
  font-size: 1.5rem;
  line-height: 1.4;
  text-align: right;
}
@media only screen and (max-width: 1023px) {
  .c-article-references__item::before {
    font-size: 1.125rem;
    line-height: inherit;
  }
}

.c-article-references__item > p.c-article-references__text,
.c-article-references__item > h3 {
  flex: 1;
  padding-left: 8px;
}

.c-article-references__text {
  margin-bottom: 8px;
}

.c-article-references__links {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-article-references__links a {
  padding-left: 8px;
}

.c-article-references__links a:first-child {
  padding-left: 0;
}

.c-article-references__download {
  text-align: right;
}

.c-article-references__download > a {
  font-size: 1rem;
  font-weight: bold;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-article-references__download svg {
  margin-left: 4px;
  margin-top: 2px;
}

.c-article-author-information__list {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-author-information__authors-list {
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: bold;
}

.c-article-author-information__item {
  margin-bottom: 16px;
}

.c-article-author-information__subtitle {
  font-weight: 700;
  margin: 0 0 8px 0;
}

.c-article-author-affiliation__list > li {
  margin-bottom: 16px;
}

.c-article-body .c-article-author-affiliation__address {
  color: inherit;
  font-weight: 700;
  margin: 0;
}

.c-article-body .c-article-author-affiliation__authors-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.c-article-body .c-article-author-affiliation__authors-item {
  display: inline;
  margin-left: 0;
}

.c-article-authors__list {
  padding: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-authors-search {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-authors-search__title {
  color: rgb(97.75, 97.75, 97.75);
  font-weight: 700;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.05rem;
}

.c-article-authors-search__item {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-article-authors-search__text {
  margin: 0;
}

.c-article-author-institutional-author {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-author-institutional-author__name {
  font-size: 1.05rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.c-article-author-institutional-author__author-list {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 24px;
}
.c-article-author-institutional-author__author-list--popup {
  clear: both;
  margin-bottom: 16px;
}

.c-article-author-institutional-author__author-name {
  display: inline;
  list-style: none;
}

.c-article-author-institutional-author__sub-division {
  line-height: 1.76;
  margin: 0;
}

.c-article-author-institutional-author__author-list .c-article-author-institutional-author__author-list .c-article-author-institutional-author__sub-division {
  color: rgb(97.75, 97.75, 97.75);
}

.c-article-supplementary {
  margin-bottom: 24px;
}
.c-article-supplementary__item {
  margin-bottom: 24px;
  position: relative;
}
.c-article-supplementary__item > span {
  width: 40px;
  line-height: 1.4;
  font-weight: 400;
  text-align: right;
  position: absolute;
  font-size: 1.5rem;
}
.c-article-supplementary__external {
  margin-bottom: 16px;
}
.c-article-supplementary__description > * {
  margin-bottom: 0;
}

.c-article-fullwidth-content__infographic {
  position: relative;
  margin-bottom: 24px;
  padding: 0;
}
.c-article-fullwidth-content__image {
  border: 4px solid green;
  position: relative;
}
.c-article-fullwidth-content__image img {
  max-width: 100%;
}
.c-article-fullwidth-content__caption {
  margin: 1px 0 20px 0;
}

.c-article-supplementary__title {
  margin: 0 0 8px;
  line-height: 1.5;
}

.c-article-supplementary--enumerated .c-article-supplementary__title,
.c-article-supplementary--enumerated .c-article-supplementary__description {
  margin-left: 50px;
}

.c-article-further-reading__list {
  margin: 0;
}

.c-article-further-reading__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  margin-bottom: 16px;
  position: relative;
}
.c-article-further-reading__item:last-child {
  border: none;
}

.c-article-further-reading__title {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.c-article-further-reading__authors-list {
  padding: 0;
  list-style: none;
  margin: 8px 0 0 0;
  font-size: 0.813rem;
  line-height: 1.4;
  color: rgb(97.75, 97.75, 97.75);
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-article-further-reading__authors-list li {
  display: inline;
}

.c-article-further-reading__journal-title {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.813rem;
  margin-bottom: 24px;
  padding: 0;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-article-license {
  font-size: 1rem;
  margin-bottom: 24px;
}

.c-article-license__link {
  float: left;
  margin-right: 10px;
}

.c-article-license__badge {
  margin-top: 8px;
}

.c-article-subject-list {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 24px;
}

.c-article-subject-list__subject {
  background-color: rgb(225.25, 225.25, 225.25);
  border-radius: 20px;
  padding: 4px 10px;
  font-weight: 700;
  margin-right: 15px;
  margin-bottom: 16px;
  flex: 0 1 auto;
}

.c-article-satellite-article-link {
  font-family: "Merriweather", serif;
}

.c-article-compound-container {
  margin-bottom: 16px;
}

.c-article-compound-details {
  display: inline-block;
  margin-bottom: 16px;
}

.c-article-compound-details p {
  margin-bottom: 16px;
}

.c-article-compound-details__image {
  margin-bottom: 16px;
  max-width: 100%;
}

.c-article-compound-details__links {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.875rem;
}

.c-article-compound-details__synonym {
  margin-bottom: 16px;
}

.c-article-compound-details__synonym-list {
  padding: 0;
  list-style: none;
}

.c-article-compound-details__synonym-list > li {
  display: inline;
}

.c-bibliographic-information {
  display: flex;
  padding-top: 8px;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information {
    padding-top: 0;
    display: block;
  }
}
.c-bibliographic-information__value {
  font-size: 1rem;
  display: block;
}

.c-bibliographic-information__column:first-child {
  width: 81px;
  flex: 0 0 81px;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information__column:first-child {
    width: 100%;
  }
}

.c-bibliographic-information__column:last-child {
  flex: 1;
}

.c-bibliographic-information__column--border {
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
  margin-right: 24px;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information__column--border {
    border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-right: 0;
    margin-right: 0;
  }
}

.c-bibliographic-information__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 32px;
}
.c-bibliographic-information__list-item {
  flex: 0 0 calc((100% - 64px) / 3);
  box-sizing: border-box;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information__list-item {
    flex: 0 0 100%;
  }
}
.c-bibliographic-information__list-item h4 {
  font-style: normal;
  font-size: 1rem;
}
.c-bibliographic-information__list-item p {
  margin-bottom: 0;
}
.c-bibliographic-information__list-item:last-child {
  padding-right: 0;
}
.c-bibliographic-information__list-item--full-width {
  flex: 0 0 100%;
}

.c-bibliographic-information__citation,
.c-bibliographic-information__download-citation {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  margin-bottom: 8px;
}

.c-bibliographic-information__download-citation {
  margin-bottom: 24px;
}
.c-bibliographic-information__download-citation svg {
  margin-left: 4px;
  margin-top: 2px;
}

.c-code-block {
  padding: 20px;
  margin: 0 0 24px 0;
  border: 1px solid white;
  font-family: monospace;
}

.c-code-block__heading {
  margin-bottom: 16px;
  font-weight: normal;
}

.c-code-block__line {
  word-wrap: break-word;
  white-space: pre-wrap;
  display: block;
}

.c-stack {
  display: inline-table;
  vertical-align: top;
}

.c-stack sup,
.c-stack sub {
  display: table-row;
  line-height: 1;
}

.c-literal {
  font-family: monospace;
}

.c-blockquote {
  border-left: 4px solid rgb(212.5, 212.5, 212.5);
  padding-left: 16px;
  margin: 24px 0;
  position: relative;
}
.c-blockquote::before, .c-blockquote::after {
  content: "";
  display: table;
}
.c-blockquote::after {
  clear: both;
}
.c-blockquote:before, .c-blockquote:after {
  height: 7px;
  border-left: 4px solid white;
  position: absolute;
  margin-left: -4px;
  left: 0;
}
.c-blockquote:before {
  top: 0;
}
.c-blockquote:after {
  bottom: 0;
}
.c-blockquote:first-child {
  margin-top: 0;
}
.c-blockquote__body > * {
  margin-bottom: 0;
}
.c-blockquote--motto {
  border-color: #025e8d;
}

.c-book-review {
  margin-top: 40px;
  margin-bottom: 24px;
  padding: 30px 0 20px 20px;
  border-bottom: 2px solid;
  border-top: 2px solid;
  border-image: linear-gradient(to right, white, white 23%, white 0, white 74%, white 23%, white) 10;
  font-size: 0.813rem;
}
.c-book-review__title {
  font-weight: 700;
  font-size: 1.05rem;
  line-height: 1.3;
}
.c-book-review__image {
  margin: 10px 30px 10px 0;
  float: left;
}
.c-book-review img {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}
.c-book-review__list {
  padding: 0;
  list-style: none;
  display: block;
  visibility: visible;
  margin-top: 0;
  margin-bottom: 10px;
}
.c-book-review__list:last-child {
  margin-bottom: 24px;
}
.c-book-review__item {
  display: inline;
}
.c-book-review__additional-info {
  margin-bottom: 10px;
}

.c-key-step {
  border: 1px solid blue;
  margin-bottom: 24px;
  padding: 15px 15px 6px 15px;
}

.c-key-step--important {
  border-color: #f58220;
}

.c-key-step__heading {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'125%2025%2016%2016'%20enable-background%3D'new%20125%2025%2016%2016'%3E%3Cpath%20fill%3D'%23215F90'%20d%3D'M133%2025c-4.374%200-8%203.626-8%208s3.626%208%208%208%208-3.626%208-8-3.626-8-8-8zm1.173%2012.48c0%20.107%200%20.32-.32.32h-1.707c-.213%200-.32-.106-.32-.32v-5.76c0-.213.106-.32.32-.32h1.707c.213%200%20.32.213.32.32v5.76zM133%2030.333c-1.067%200-1.173-.534-1.173-1.067%200-.64.213-1.066%201.173-1.066s1.173.426%201.173%201.066c0%20.534-.106%201.067-1.173%201.067z'%2F%3E%3C%2Fsvg%3E") no-repeat 0 50%;
  color: blue;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  padding-left: 20px;
  text-transform: uppercase;
}

.c-key-step--important > .c-key-step__heading {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'109.508%2034.751%2016%2016'%20enable-background%3D'new%20109.508%2034.751%2016%2016'%3E%3Cpath%20fill%3D'%23AB5306'%20d%3D'M125.382%2048.933l-7.35-12.914c-.314-.526-.734-.526-1.05%200l-7.35%2012.913c-.314.525%200%20.945.526.945h14.7c.524%200%20.84-.42.524-.945zm-8.61-9.555h1.47c.21%200%20.316.105.316.315l-.21%205.774c0%20.21-.105.315-.315.315h-1.05c-.21%200-.315-.105-.315-.315l-.21-5.774c0-.21.104-.315.315-.315zm.736%209.45c-1.05%200-1.155-.525-1.155-1.05%200-.63.21-1.05%201.155-1.05.945%200%201.155.42%201.155%201.05%200%20.525-.21%201.05-1.155%201.05z'%2F%3E%3C%2Fsvg%3E");
  color: #f58220;
}

.c-key-step__body {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 1rem;
  margin: 0;
}

.c-key-step__body > p {
  margin-bottom: 16px;
}

.c-troubleshooting-step {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'125%2025%2016%2016'%20enable-background%3D'new%20125%2025%2016%2016'%3E%3Cpath%20fill%3D'%23215F90'%20d%3D'M133%2025c-4.374%200-8%203.626-8%208s3.626%208%208%208%208-3.626%208-8-3.626-8-8-8zm1.173%2012.48c0%20.107%200%20.32-.32.32h-1.707c-.213%200-.32-.106-.32-.32v-5.76c0-.213.106-.32.32-.32h1.707c.213%200%20.32.213.32.32v5.76zM133%2030.333c-1.067%200-1.173-.534-1.173-1.067%200-.64.213-1.066%201.173-1.066s1.173.426%201.173%201.066c0%20.534-.106%201.067-1.173%201.067z'%2F%3E%3C%2Fsvg%3E") no-repeat 0 50%;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  padding-left: 20px;
  text-transform: uppercase;
}

.c-timing-step {
  color: rgb(97.75, 97.75, 97.75);
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  margin: 0 0 8px 0;
}

.c-article-box {
  border: 2px solid green;
  margin: 0 0 24px 0;
  padding: 0;
}

.c-article-box__container {
  margin: 0;
  overflow: hidden;
  padding: 20px 20px 30px 20px;
}

.c-article-box__container-title {
  margin-bottom: 8px;
  font-weight: bold;
}

.c-article-box__content > * {
  margin-bottom: 0;
}

.c-article-box__content > * + * {
  margin-top: 24px;
}

.c-article-box__controls {
  position: relative;
  margin-top: -56px;
}

.c-article-box__controls button {
  display: block;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: green;
  color: #025e8d;
  border: none;
  padding: 10px 20px 6px 20px;
  width: 100%;
  cursor: pointer;
}
.c-article-box__controls button:hover, .c-article-box__controls button:focus {
  text-decoration: underline;
}

.c-article-box__button-text:after {
  content: "";
  width: 20px;
  height: 10px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'12'%20height%3D'7'%20viewBox%3D'0%200%2012%207'%3E%3Cpath%20fill%3D'%230000FF'%20d%3D'M9.6%200l-3.6%204.3-3.6-4.3h-2.4l6%207%206-7h-2.4z'%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: inline-block;
}

.c-article-box--expanded .c-article-box__button-text:after {
  transform: rotate(180deg);
}

.c-article-box__fade {
  height: 56px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.38) 25%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0.85) 75%, #fff 100%);
}

.c-article-box--expanded .c-article-box__controls {
  margin-top: 0;
}

.c-article-box--expanded .c-article-box__fade {
  display: none;
}

.c-article-footnote {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  margin-bottom: 24px;
  padding: 20px 30px;
}
.c-article-footnote p {
  margin-bottom: 0;
}
.c-article-footnote p + p {
  margin-top: 16px;
}

.c-article-footnote--listed {
  border: none;
  padding: 0;
}
.c-article-footnote--listed__item {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-article-footnote--listed__content {
  flex: 1;
  padding-left: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.c-article-footnote--listed__item::before {
  content: attr(data-counter);
  font-size: 1.5rem;
  line-height: 1.4;
  text-align: right;
}

.c-article-share-box {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 24px;
}
.c-article-share-box__title {
  font-weight: 700;
  margin-bottom: 8px;
}
.c-article-share-box__description {
  font-size: 1rem;
  margin-bottom: 8px;
}
.c-article-share-box__no-sharelink-info {
  font-size: 0.813rem;
  font-weight: 700;
  padding-top: 4px; /* make equal height with share button to prevent redraw */
  margin-bottom: 24px;
}
.c-article-share-box__only-read-input {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  display: inline-block;
  box-sizing: content-box;
  height: 24px;
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 8px 10px;
}
.c-article-share-box__only-read-input--highlighted {
  animation: "transparent-to-yellow" 1.5s ease;
}
@keyframes transparent-to-yellow {
  0% {
    background: transparent;
  }
  25% {
    background: #ffcc00;
  }
  75% {
    background: #ffcc00;
  }
  100% {
    background: transparent;
  }
}
.c-article-share-box__additional-info {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.813rem;
}
.c-article-share-box__button {
  background: white;
  border: 1px solid #025e8d;
  box-sizing: content-box;
  color: #025e8d;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 8px;
  padding: 8px 20px;
  text-align: center;
}
.c-article-share-box__button--link-like {
  background-color: transparent;
  border: none;
  color: #025e8d;
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 8px;
  margin-left: 10px;
}

.c-pan-zoom-controls {
  text-align: center;
  background: green;
  border-bottom: 6px solid green;
  padding: 8px 10px;
}
.c-pan-zoom-controls ul {
  list-style: none;
  display: inline-flex;
  margin: 0;
  padding: 0;
}
.c-pan-zoom-controls li {
  border-left: 1px solid white;
  padding: 4px;
}
.c-pan-zoom-controls li:first-child {
  border-left: none;
}
.c-pan-zoom-controls button {
  border: none;
  padding: 0 10px;
  background-color: transparent;
  color: #025e8d;
}
.c-pan-zoom-controls button:hover,
.c-pan-zoom-controls button:focus {
  text-decoration: underline;
}

.c-pan-zoom-controls__zoom-in button,
.c-pan-zoom-controls__zoom-out button {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 16px auto;
  width: 16px;
  height: 16px;
  padding: 4px 20px;
}

.c-pan-zoom-controls__zoom-in button {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%230000FF'%20d%3D'M6%200h4v16h-4v-16zm-6%206h16v4h-16v-4z'%2F%3E%3C%2Fsvg%3E");
}

.c-pan-zoom-controls__zoom-out button {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%230000FF'%20d%3D'M0%206h16v4h-16v-4z'%2F%3E%3C%2Fsvg%3E");
}

.c-article-equation {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  display: table;
  table-layout: fixed;
}
.c-article-equation .MathJax_Display > .MathJax {
  display: block !important;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.c-article-equation #MathJax_Zoom {
  background-color: #f7fbfe;
  border: 1px solid #97bfd8;
  border-radius: 2px;
  box-shadow: 0 0 5px 2px rgba(51, 51, 51, 0.2);
}
.c-article-equation__content {
  display: block;
  text-align: left;
  vertical-align: middle;
}
@media only screen and (min-width: 876px) {
  .c-article-equation__content {
    display: table-cell;
    width: 90%;
  }
}
.c-article-equation__content > .MathJax {
  display: block !important;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.c-article-equation__number {
  display: block;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
  vertical-align: middle;
  margin: 12px 0 16px;
}
@media only screen and (min-width: 876px) {
  .c-article-equation__number {
    display: table-cell;
    width: 10%;
    white-space: nowrap;
    text-align: right;
    padding-left: 16px;
  }
}

.MathJax_SVG svg {
  max-width: 100%;
}

#MathJax_Zoom svg {
  max-width: none;
}

.c-article-equation__number {
  min-width: 35px;
}

.c-article-orcid {
  margin-block-start: 0;
}
.c-article-orcid__text {
  font-size: 1rem;
}
.c-article-orcid:before {
  display: inline-block;
  width: 24px;
  height: 24px;
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='m0 0h24v24h-24z' fill='%23fff' opacity='0'/%3E%3Cpath d='m12 20c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm-3.01799709-13.09169471c-.16628978-.16109322-.36635416-.24163862-.60019916-.24163862-.21825533 0-.410525.08444276-.57681478.25333081-.16628977.16888806-.24943341.36245684-.24943341.58071218 0 .22345188.08314364.41961891.24943341.58850696.16628978.16888806.35855945.25333082.57681478.25333082.233845 0 .43390938-.08314364.60019916-.24943342.16628978-.16628977.24943342-.36375592.24943342-.59240436 0-.233845-.08314364-.43131115-.24943342-.59240437zm-1.30173065 2.36492841v7.1712107h1.41865256v-7.1712107zm5.97732434.02508949h-2.9924703v7.14612121h2.9106249c.7673039 0 1.4476365-.1483432 2.0410182-.445034s1.0511995-.7152915 1.3734671-1.2558144c.3222677-.540523.4833991-1.1603247.4833991-1.8594238 0-.6854582-.1602789-1.3027023-.4808414-1.8517509-.3205625-.5490485-.7707074-.9753221-1.3504481-1.2788334-.5797408-.30351132-1.2413173-.45526471-1.9847495-.45526471zm-.1432294 6.06678581h-1.4681008v-4.9874504h1.4220628c.7877654 0 1.4143875.2233674 1.8798852.6701087.4654977.4467414.698243 1.05546.698243 1.8261742 0 .7434322-.2310402 1.3444779-.6931277 1.8031551-.4620874.4586773-1.0750688.6880124-1.8389625.6880124z' fill='%23999'/%3E%3C/g%3E%3C/svg%3E");
  margin-left: -3px;
  padding-right: 4px;
  vertical-align: middle;
}

.c-article-editorial-summary__container {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
}
.c-article-editorial-summary__container .c-article-editorial-summary__article-title {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}
.c-article-editorial-summary__container .c-article-editorial-summary__content p:last-child {
  margin-bottom: 0;
}
.c-article-editorial-summary__container .c-article-editorial-summary__content--less {
  max-height: 9.5rem;
  overflow: hidden;
}
.c-article-editorial-summary__container .c-article-editorial-summary__button {
  background-color: white;
  border: none;
  color: #025e8d;
  font-size: 0.875rem;
  margin-bottom: 16px;
}
.c-article-editorial-summary__container .c-article-editorial-summary__button.active, .c-article-editorial-summary__container .c-article-editorial-summary__button:active, .c-article-editorial-summary__container .c-article-editorial-summary__button.hover, .c-article-editorial-summary__container .c-article-editorial-summary__button:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-article-associated-content__container .c-article-associated-content__collection-label {
  font-size: 0.875rem;
  line-height: 1.4;
}
.c-article-associated-content__container .c-article-associated-content__collection-title {
  line-height: 1.3;
}

#uptodate-client {
  display: none !important;
}

/**
 * Copied from https://github.com/springernature/bunsen/blob/master/webapp/src/webapp/assets/scss/partials/_sticky-banner.scss
 * Sticky Banner
 * Persistent (after scrolling past sticky point) horizontal bar
 * at top of page containing call-to-action elements
 *
 */
.c-context-bar {
  position: relative;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
}

.c-context-bar--sticky {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
}
@media only screen and (max-width: 320px) {
  .c-context-bar--sticky {
    left: auto;
    right: 0;
  }
}
.c-context-bar--sticky:after {
  background: #fff;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
}
@supports (backdrop-filter: blur(10px)) {
  .c-context-bar--sticky:after {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.75) 50%, #fff);
    backdrop-filter: blur(10px);
  }
}
@media only screen and (max-width: 320px) {
  .c-context-bar--sticky:after {
    background-color: #fcfcfc;
    background-image: none;
    box-shadow: none;
  }
}

.c-context-bar--no-button.c-context-bar--sticky {
  display: none;
}
@media only screen and (min-width: 768px) {
  .c-context-bar--no-button.c-context-bar--sticky {
    display: block;
  }
}

.c-context-bar--sticky .c-context-bar__container {
  padding: 16px;
}
@media only screen and (min-width: 768px) {
  .c-context-bar--sticky .c-context-bar__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    min-height: 40px;
  }
}

.c-context-bar__title {
  display: none;
}

.c-context-bar--sticky .c-context-bar__title,
.c-context-bar--sticky .c-context-bar__title p {
  display: none;
}
@media only screen and (min-width: 768px) {
  .c-context-bar--sticky .c-context-bar__title,
  .c-context-bar--sticky .c-context-bar__title p {
    display: block;
    flex: 1;
    padding-right: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.c-context-bar--sticky .c-context-bar__title p {
  margin-bottom: 0;
}

.article__teaser {
  overflow: hidden;
  position: relative;
  font-family: "Merriweather", serif;
}
.article__teaser::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: min(200px, 50%);
  background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
}

.c-pdf-preview__embed {
  display: none;
}
@media only screen and (min-width: 768px) {
  .c-pdf-preview__embed {
    display: block;
    margin-bottom: 24px;
  }
}

.c-pdf-preview__embed--height {
  height: 1000px;
}

.c-pdf-preview__info {
  display: block;
}
@media only screen and (min-width: 768px) {
  .c-pdf-preview__info {
    display: none;
  }
}

/* Add to remove white space created by img src */
img[src*="delivery.pbgrd.com/network/logger"] {
  position: absolute;
  height: 0;
  width: 0;
}

/* Temporary until fix is in a released version of elements */
span.u-custom-list-number {
  width: fit-content;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
}

.c-download-pdf-icon-large {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  fill: #025e8d;
}

.c-external-link__icon {
  fill: #025e8d;
  vertical-align: middle;
}

/**
 * Article Companion
 * Article styles for browsers that pass CTM
 */
.c-reading-companion {
  clear: both;
  min-height: 389px;
}

.c-reading-companion .u-lazy-ad-wrapper {
  background-color: transparent !important;
  min-height: 250px;
}
.c-reading-companion .u-lazy-ad-wrapper .div-gpt-ad {
  height: 100%;
}

.c-reading-companion__heading {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 16px 16px;
  margin: 0;
}

.c-reading-companion__sticky {
  max-width: 389px;
}
.c-reading-companion__sticky--stuck {
  margin: 0 0 16px;
  position: fixed;
  top: 50px;
}

.c-reading-companion__scroll-pane {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 0 0;
  min-height: 200px;
}

.c-reading-companion__tabs {
  font-size: 1rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 8px;
  padding: 0;
}
.c-reading-companion__tabs > li {
  flex-grow: 1;
}

.c-reading-companion__tab {
  color: blue;
  border: solid rgb(212.5, 212.5, 212.5);
  border-width: 1px 1px 1px 0;
  background-color: #eeeeee;
  padding: 8px 8px 8px 15px;
  text-align: left;
  font-size: 1rem;
  width: 100%;
}
.c-reading-companion__tab:focus {
  text-decoration: none;
  outline-offset: -4px;
}

.c-reading-companion__tabs li:first-child .c-reading-companion__tab {
  border-left-width: 1px;
}

.c-reading-companion__tab--active {
  color: #222222;
  background-color: #fcfcfc;
  border-bottom: 1px solid #fcfcfc;
  font-weight: bold;
}

.c-reading-companion--highlighted {
  -webkit-animation: highlight 2s ease;
  animation: highlight 2s ease;
}

@-webkit-keyframes highlight {
  0% {
    background-color: #ff9;
  }
  60% {
    background-color: #ff9;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes highlight {
  0% {
    background-color: #ff9;
  }
  60% {
    background-color: #ff9;
  }
  100% {
    background-color: #fff;
  }
}
.c-reading-companion__sections-list,
.c-reading-companion__figures-list,
.c-reading-companion__references-list {
  list-style: none;
  padding: 0;
  min-height: 389px;
}

.c-reading-companion__references-list--numeric {
  list-style: decimal inside;
}

.c-reading-companion__sections-list {
  margin: 0 0 8px;
  min-height: 50px;
}

.c-reading-companion__section-item {
  font-size: 1rem;
  padding: 0;
}
.c-reading-companion__section-item a {
  display: block;
  padding: 8px 0 8px 16px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.c-reading-companion__section-item--active a {
  white-space: normal;
  border-left: 4px solid rgb(212.5, 212.5, 212.5);
  padding-left: 12px;
  color: #222222;
}
.c-reading-companion__section-item .c-article-section__title-number {
  display: none;
}

.c-reading-companion__figure-item {
  padding: 16px 8px 16px 0;
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  font-size: 1rem;
}
.c-reading-companion__figure-item:first-child {
  border-top: none;
  padding-top: 8px;
}

.c-reading-companion__reference-item {
  padding: 8px 8px 8px 16px;
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  font-size: 1rem;
}
.c-reading-companion__reference-item:first-child {
  border-top: none;
}
.c-reading-companion__reference-item a {
  word-break: break-word;
}

.c-reading-companion__reference-citation {
  display: inline;
}

.c-reading-companion__reference-links {
  list-style: none;
  text-align: right;
  margin: 8px 0 0;
  padding: 0;
  font-weight: bold;
  font-size: 0.813rem;
}
.c-reading-companion__reference-links > a {
  display: inline-block;
  padding-left: 8px;
}
.c-reading-companion__reference-links > a:first-child {
  display: inline-block;
  padding-left: 0;
}

.c-reading-companion__figure-title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  display: block;
  margin: 0 0 8px;
}

.c-reading-companion__figure-links {
  margin: 8px 0 0;
  display: flex;
  justify-content: space-between;
}

.c-reading-companion__figure-links > a {
  display: flex;
  align-items: center;
}

.c-reading-companion__figure-item picture:last-child {
  margin-bottom: 16px;
}

.c-reading-companion__figure-full-link svg {
  height: 0.8em;
  margin-left: 2px;
}

.c-reading-companion__panel {
  display: none;
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.c-reading-companion__panel--active {
  display: block;
}

.c-reading-companion__return {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
}
.c-reading-companion__return:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0 4px;
}

.c-behind-the-paper {
  display: none;
}

/**
 * Article Tables and Figures
 * Article styles for browsers that pass CTM
 */
.c-article-section__figure {
  border: 2px solid rgb(212.5, 212.5, 212.5);
  padding: 20px 10px;
  max-width: 100%;
  margin-bottom: 24px;
  clear: both;
}

.c-article-section__figure--1-border {
  border-width: 1px;
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
}

.c-article-section__figure--1-border-image {
  float: left;
  max-width: 100%;
}

.c-article-section__figure--no-border {
  border: none;
  padding: 20px 0;
}

.c-article-section__figure-caption {
  margin-bottom: 8px;
  display: block;
  word-break: break-word;
}

.c-article-section__figure-1-border-caption {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.813rem;
  margin: 0;
  padding: 8px 0;
  max-width: 100%;
}

.c-article-section__figure-credit {
  font-size: 0.813rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 16px;
}

.c-article-section__figure-credit-right {
  text-align: right;
}

.c-article-section__figure-content {
  margin-bottom: 16px;
}

.c-article-section__figure-item {
  max-width: 100%;
}

.c-article-section__figure-link {
  max-width: 100%;
  display: block;
  margin: 0 0 16px;
  padding: 0;
}

.c-article-section__figure .video {
  margin: 0 0 16px;
}

.c-article-section__figure-item img {
  display: block;
  margin: 0 auto;
}

.c-article-section__figure-description {
  font-size: 1rem;
}

.c-article-section__figure-description > * {
  margin-bottom: 0;
}

.c-article-figure-content {
  clear: both;
}

.c-article-figure-content-image {
  margin-bottom: 16px;
  text-align: center;
}

.c-article-figure-content-image:before,
.c-article-figure-content-image:after {
  content: " ";
  display: table;
}

.c-article-figure-content-image:after {
  clear: both;
}

.c-article-figure-content-image-inline {
  display: inline-block;
}

.c-article-figure-description {
  font-family: "Merriweather", serif;
  margin: 0 0 24px 0;
}

.c-article-figure-description > * {
  margin-bottom: 0;
}

.c-article-figure-description > * + * {
  margin-top: 24px;
}

.c-article-figure-button-container,
.c-article-metrics__button-container {
  text-align: right;
}

.c-article-table {
  border: 2px solid rgb(212.5, 212.5, 212.5);
  clear: both;
  padding: 20px 10px;
  margin-bottom: 24px;
}

.c-article-table-image {
  text-align: center;
}

.c-article-table__figcaption,
.c-article-satellite-title {
  line-height: 1.4;
  margin-bottom: 16px;
  word-break: break-word;
}

.c-article-satellite-subtitle {
  font-size: 1.125rem;
  font-family: "Merriweather", serif;
  margin-bottom: 16px;
}

.c-article-table__inline-caption {
  font-weight: normal;
  font-size: 1.05rem;
  margin-bottom: 16px;
}

.c-article-table-container {
  font-family: "Merriweather", serif;
  margin-bottom: 24px;
}

.c-article-table-border {
  border: 1px solid rgb(212.5, 212.5, 212.5);
}

.c-article-table-container table {
  border: 2px solid rgb(212.5, 212.5, 212.5);
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0;
  width: 100%;
}

.c-article-table-container th,
.c-article-table-container td {
  padding: 6px;
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
  line-height: 1.15;
}

.c-article-table-container th p,
.c-article-table-container td p {
  word-break: normal;
}

.c-article-table-head {
  border-bottom: 5px solid rgb(148.75, 148.75, 148.75);
}

.c-article-table-footer {
  padding: 10px;
}

.c-article-table-footer ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-table-scroll-wrapper {
  position: relative;
}

.c-table-scroll-wrapper__content {
  overflow-x: auto;
  min-width: 100%;
}

.c-table-scroll-wrapper__fade::after {
  content: "";
  width: 30px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), #fff);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  will-change: opacity;
}

.c-table-scroll-wrapper__fade--transparent::after {
  opacity: 0;
}

.c-aod {
  background: #dae5ea;
  padding: 20px;
  margin-bottom: 24px;
  font-size: 1rem;
}

.c-aod__prompt {
  display: block;
  margin-bottom: 8px;
}

.c-aod__invalid {
  display: block;
  margin-top: 8px;
}

.c-aod input {
  padding: 5px;
  border: 1px solid #025e8d;
}

.c-aod input[type=submit] {
  background: #025e8d;
  color: white;
  padding: 5px 10px;
}

.c-access-options {
  margin: 16px 0 40px;
}
.c-access-options__heading {
  font-weight: bold;
}
.c-access-options__list {
  margin: 0;
  padding: 0;
  list-style: disc inside;
}
.c-access-options__list > li {
  font-size: 1rem;
  line-height: 1.4;
}

.c-cod {
  font-size: 1rem;
  display: block;
  width: 100%;
}

.c-cod__form {
  background: #ebf0f3;
}

.c-cod__prompt {
  margin: 0 0 24px;
  font-size: 1.125rem;
  line-height: 1.3;
}

.c-cod__label {
  display: block;
  margin: 0 0 4px;
}

.c-cod__row {
  display: flex;
  margin: 0 0 16px;
}
.c-cod__row:last-child {
  margin: 0;
}

.c-cod__input {
  padding: 13px;
  margin: 0;
  border: 1px solid rgb(212.5, 212.5, 212.5);
  border-radius: 2px;
  flex-basis: 75%;
  flex-shrink: 0;
}

.c-cod__input--submit {
  margin-left: 8px;
  flex-shrink: 1;
  background-color: #025e8d;
  color: white;
  border: 1px solid #025e8d;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.c-cod__input--submit-single {
  margin: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

.c-cod__input--submit:hover,
.c-cod__input--submit:focus {
  color: #025e8d;
  background-color: white;
}

.c-pdf-download__link .u-icon {
  padding-top: 2px;
}

/**
 * Article Save data - article styles for all browsers with lite mode on
 * styles below override any from shared-article/50-components with font-family: $context--font-family-serif;
 */
.save-data h1,
.save-data h2,
.save-data h3,
.save-data .serif,
.save-data .u-serif,
.save-data .c-site-messages--nature-briefing-email-variant .serif,
.save-data .c-site-messages--nature-briefing-email-variant.serif,
.save-data .c-article-main-column,
.save-data .c-article-author-institutional-author__sub-division,
.save-data .c-article-fullwidth-content,
.save-data .c-article-satellite-article-link,
.save-data .c-code-block__heading,
.save-data .c-blockquote__body,
.save-data .c-article-equation__number,
.save-data .c-article-satellite-subtitle,
.save-data .c-article-satellite-article-link,
.save-data .c-article-figure-description,
.save-data .c-article-satellite-subtitle,
.save-data .c-article-table-container,
.save-data .c-reading-companion__reference-citation,
.save-data .c-reading-companion__figure-title {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* NEW STYLING FOR PDF LINKS
Remove parent selector when all pdf links are adapted consistently across SN, Magazine, BMC and SL
*/
.c-pdf-download {
  display: flex;
  margin-bottom: 16px;
  max-height: 48px;
}
@media only screen and (min-width: 768px) {
  .c-pdf-download {
    max-height: none;
  }
}
@media only screen and (min-width: 1024px) {
  .c-pdf-download {
    max-height: 48px;
  }
}

.c-pdf-download__link {
  display: flex;
  flex: 1;
  padding: 13px 24px;
}
.c-pdf-download__link:hover {
  text-decoration: none;
}

.c-pdf-download__text {
  padding-right: 4px;
}
@media only screen and (max-width: 767px) {
  .c-pdf-download__text {
    text-transform: capitalize;
  }
}
@media only screen and (min-width: 768px) {
  .c-pdf-download__text {
    padding-right: 8px;
  }
}

.c-context-bar--sticky .c-pdf-download {
  margin-bottom: 0;
  display: block;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .c-pdf-download .u-sticky-visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }
}

.c-pdf-container {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 767px) {
  .c-pdf-container .c-pdf-download {
    display: flex;
    flex-basis: 100%;
  }
}

.c-pdf-container .c-pdf-download + .c-pdf-download {
  margin-left: 16px;
}

.c-article-extras .c-pdf-container {
  width: 100%;
  flex-wrap: wrap;
}
.c-article-extras .c-pdf-container .c-pdf-download {
  width: 100%;
}
.c-article-extras .c-pdf-container .c-pdf-download + .c-pdf-download {
  margin-left: 0;
}

@media only screen and (min-width: 768px) {
  .c-context-bar--sticky .c-pdf-download__link {
    flex: 1 1 183px;
    align-items: center;
  }
}
@media only screen and (max-width: 320px) {
  .c-context-bar--sticky .c-pdf-download__link {
    padding: 16px;
  }
}

.article-page--commercial {
  /* PDF link show/hide in main body within c-pdf-button__container depending on viewport */
}
.article-page--commercial .c-pdf-container {
  display: block;
}
.article-page--commercial .c-article-main-column .c-pdf-button__container .c-pdf-download {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .article-page--commercial .c-article-main-column .c-pdf-button__container .c-pdf-download {
    display: block;
  }
}

/* Inline variant styles */
.c-article-recommendations {
  padding: 24px;
  background-color: #eff6fb;
  margin: 0 0 48px;
}
.c-article-recommendations-title {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
}

.c-book-body .c-article-recommendations {
  margin: 0 0 64px;
}

.c-book-body .c-article-recommendations-list,
.c-article-body .c-article-recommendations-list {
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 100%;
  margin: 0;
  padding: 16px 0 0;
}
.c-book-body .c-article-recommendations-list__item,
.c-article-body .c-article-recommendations-list__item {
  flex: 1;
}
@media only screen and (max-width: 767px) {
  .c-book-body .c-article-recommendations-list,
  .c-article-body .c-article-recommendations-list {
    flex-direction: column;
  }
}

.c-article-recommendations-list:before,
.c-article-recommendations-list:after {
  align-self: stretch;
  content: "";
  border-left: 1px solid #cedbe0;
}

@media only screen and (max-width: 767px) {
  .c-article-recommendations-list:before,
  .c-article-recommendations-list:after {
    border-bottom: 1px solid #cedbe0;
    border-left: none;
  }
}

.c-article-recommendations-list__item {
  display: flex;
}

.c-article-recommendations-list .c-article-recommendations-list__item:first-child {
  order: -1;
}

.c-article-recommendations-list .c-article-recommendations-list__item:last-child {
  order: 1;
}

.c-article-recommendations-card {
  display: flex;
  flex-direction: column;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: hidden;
  position: relative;
}
.c-article-recommendations-card__img {
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .c-article-recommendations-card__img {
    display: none;
  }
}

.c-article-recommendations-card__heading {
  max-height: 5.6em;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 16px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  line-height: 1.4;
}
@media only screen and (max-width: 767px) {
  .c-article-recommendations-card__heading {
    margin: 0 0 8px;
  }
}

.c-article-recommendations-card__link {
  color: #000000;
}
.c-article-recommendations-card__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.c-article-recommendations-card__link:hover, .c-article-recommendations-card__link:visited {
  color: #000000;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.08em;
}
.c-article-recommendations-card__link .MathJax_Display {
  display: inline !important;
}

.c-article-recommendations-card__main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
@media only screen and (max-width: 767px) {
  .c-article-recommendations-card__main {
    flex-direction: column-reverse;
  }
}

.c-recommendations-column-switch .c-meta {
  margin-top: auto;
}

.c-meta .c-meta__item:first-child,
.c-article-recommendations-card__meta-type {
  font-weight: 700;
}

.c-article-meta-recommendations {
  margin-top: auto;
  padding: 0;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: inherit;
}
.c-article-meta-recommendations__item-type {
  font-weight: 700;
  border-right: 1px solid #d9d9d9;
  padding-right: 8px;
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .c-article-meta-recommendations__access-type {
    border-right: 1px solid #d9d9d9;
    padding-right: 8px;
    margin-right: 8px;
  }
}

.c-article-recommendations-list:has(.c-article-meta-recommendations__access-type) .c-article-meta-recommendations:not(:has(> .c-article-meta-recommendations__access-type)) {
  padding: 0 0 1.5rem;
}
@media only screen and (max-width: 767px) {
  .c-article-recommendations-list:has(.c-article-meta-recommendations__access-type) .c-article-meta-recommendations:not(:has(> .c-article-meta-recommendations__access-type)) {
    padding: 0;
  }
}

.c-article-meta-recommendations__access-type + .c-article-meta-recommendations__date {
  display: block;
}
@media only screen and (max-width: 767px) {
  .c-article-meta-recommendations__access-type + .c-article-meta-recommendations__date {
    display: inline;
  }
}

.c-article-body .c-article-recommendations-card__authors {
  display: none;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0 0 8px;
}
@media only screen and (max-width: 767px) {
  .c-article-body .c-article-recommendations-card__authors {
    display: block;
    margin: 0;
  }
}

/**
 * Article History
 * Article styles for the article history section found in "about the article/chapter"
 */
.c-article-history {
  border-left: 2px dotted #999;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .c-article-history {
    margin-left: 96px;
  }
}

.c-article-body .c-article-history {
  margin-top: 24px;
}

.c-article-history-item {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  padding: 0 0 24px 14px;
  margin-left: -2px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .c-article-history-item {
    padding-left: 26px;
  }
}
.c-article-history-item:before {
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #999;
  left: -5px;
  top: 7px;
  padding: 0;
  content: "";
  position: absolute;
}
.c-article-history-item:first-child {
  background-image: linear-gradient(to bottom, #fff 0, #fff 12px, transparent 12px, transparent);
}
.c-article-history-item:last-child {
  background-image: linear-gradient(to bottom, transparent 0, transparent 12px, #fff 12px, #fff);
}
.c-article-history-item--current:before {
  background: #999;
}
.c-article-history-item__marker {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .c-article-history-item__marker {
    font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: absolute;
    left: -100px;
    top: 1px;
    text-align: right;
    width: 96px;
    padding-right: 8px;
  }
}
.c-article-history-item__title {
  margin-bottom: 12px;
  display: inline-block;
}
.c-article-history-item__authors {
  margin: 0;
  padding: 0;
}
.c-article-history-item__authors li {
  display: inline;
}
.c-article-history-item__authors li:after {
  content: ",";
}
.c-article-history-item__authors li:last-child:after {
  content: "";
}
.c-article-history-item .c-article-history-item__dates {
  line-height: 1.8;
  margin: 0;
}
.c-article-history-item .c-article-history-item__dates dd,
.c-article-history-item .c-article-history-item__dates dt {
  display: inline;
  margin: 0;
  padding: 0 0.25em 0 0;
}
.c-article-history-item .c-article-history-item__dates dt {
  font-weight: 700;
}
.c-article-history-item__doi {
  margin: 16px 0;
}
.c-article-history-item__changelog h3 {
  font-size: 0.875rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.7;
  margin-bottom: 0;
}
.c-article-history-item__changelog p:first-of-type {
  margin-top: 0;
}

.c-article-history-item a {
  font-size: 1.125rem;
  line-height: 1.5;
}

/**
 * Darwin masthead
 * Masthead styles for browsers that pass CTM
 */
.app-article-masthead {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0 24px;
}
.app-article-masthead__info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.app-article-masthead__brand {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-basis: fit-content;
  flex-shrink: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  padding: 16px 0 0;
  min-height: 96px;
}
.app-article-masthead__brand img {
  position: absolute;
  left: 0;
  width: 72px;
  height: auto;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 15px 0px rgba(128, 128, 128, 0.25);
  border-radius: 8px;
}
.app-article-masthead__syndicated-card {
  border-top: 10px solid #c5e0f4;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 16px;
}
.app-article-masthead__syndicated-card-heading {
  color: #000000;
  font-size: 1.125rem;
  font-size: min(max(1.125rem, 2vw), 1.25rem);
  margin: 0 0 16px;
}
.app-article-masthead__syndicated-card-link {
  text-decoration: none;
}
.app-article-masthead__syndicated-card-from, .app-article-masthead__syndicated-card-journal {
  display: block;
}
.app-article-masthead__syndicated-card-main {
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.app-article-masthead__journal-link {
  max-width: 400px;
  position: relative;
  display: block;
  padding: 0 0 0 88px;
  margin: 0 0 8px;
  font-size: 1.125rem;
  font-size: min(max(1.125rem, 2vw), 1.25rem);
  font-weight: 700;
}
.app-article-masthead__conference-link {
  margin: 0;
  gap: 0;
}
.app-article-masthead__conference-info {
  padding: 8px 0 0 88px;
}
.app-article-masthead__journal-title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.app-article-masthead__submission-link {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 0 88px;
  font-size: 1rem;
  font-size: min(max(0.875rem, 2vw), 1rem);
}
.app-article-masthead__access {
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 300;
  flex-wrap: wrap;
}
.app-article-masthead__buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
}

.app-article-masthead__access svg {
  fill: currentColor;
}

p.app-article-masthead__access--above-download {
  margin: 0 0 16px;
}

.app-article-masthead__brand .app-article-masthead__syndicated-card-image {
  border: none;
  border-radius: 0;
  box-shadow: none;
  position: static;
}
.app-article-masthead__brand .app-article-masthead__syndicated-card-image--title {
  max-width: 368px;
  width: auto;
}
.app-article-masthead__brand .app-article-masthead__syndicated-card-image--cover {
  display: none;
  max-width: 400px;
  margin: 16px -16px 0;
}

@media only screen and (min-width: 768px) {
  .app-article-masthead__brand .app-article-masthead__syndicated-card-image--cover {
    display: block;
  }
}
.app-article-masthead a,
.app-article-masthead a:visited {
  color: #ffffff;
}

.app-masthead--pastel .app-article-masthead a,
.app-masthead--pastel .app-article-masthead a:visited {
  color: #000000;
}

.app-masthead--pastel .app-article-masthead .c-article-identifiers *,
.app-masthead--pastel .app-article-masthead .c-article-identifiers a:focus,
.app-masthead--pastel .app-article-masthead .c-article-identifiers a:hover {
  color: #000000;
}

.app-masthead--pastel .app-article-masthead .c-article-identifiers__item {
  border-left: 1px solid #000000;
}

.app-masthead--pastel .c-pdf-download .u-button--primary,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary {
  color: white;
  background-color: #01324b;
  background-image: none;
  border: 4px solid transparent;
  box-shadow: 0 0 0 1px #01324b;
  font-weight: 700;
  border: 2px solid transparent;
  background-color: #025e8d;
  box-shadow: none;
}
.app-masthead--pastel .c-pdf-download .u-button--primary svg,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary svg {
  fill: currentColor;
}
.app-masthead--pastel .c-pdf-download .u-button--primary:visited,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:visited {
  color: white;
}
.app-masthead--pastel .c-pdf-download .u-button--primary:hover,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:hover {
  border: 4px solid white;
  box-shadow: 0 0 0 1px #01324b;
  text-decoration: none;
}
.app-masthead--pastel .c-pdf-download .u-button--primary:focus,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:focus {
  border: 4px solid #ffcc00;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}
.app-masthead--pastel .c-pdf-download .u-button--primary:hover, .app-masthead--pastel .c-pdf-download .u-button--primary:focus,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:hover,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:focus {
  color: #01324b;
  background-color: white;
  background-image: none;
}
.app-masthead--pastel .c-pdf-download .u-button--primary:hover svg path, .app-masthead--pastel .c-pdf-download .u-button--primary:focus svg path,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:hover svg path,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:focus svg path {
  fill: #01324b;
}
.app-masthead--pastel .c-pdf-download .u-button--primary:hover,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--primary:hover {
  background: transparent;
  border: 2px solid #025e8d;
  box-shadow: none;
  color: #025e8d;
}

.app-masthead--pastel .c-pdf-download .u-button--secondary,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary {
  color: #01324b;
  background-color: white;
  border: 4px solid white;
  font-weight: 700;
  border: 2px solid #025e8d;
  background: transparent;
  color: #025e8d;
}
.app-masthead--pastel .c-pdf-download .u-button--secondary svg,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary svg {
  fill: currentColor;
}
.app-masthead--pastel .c-pdf-download .u-button--secondary:visited,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:visited {
  color: #01324b;
}
.app-masthead--pastel .c-pdf-download .u-button--secondary:hover,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:hover {
  border: 4px solid #01324b;
  box-shadow: none;
}
.app-masthead--pastel .c-pdf-download .u-button--secondary:hover, .app-masthead--pastel .c-pdf-download .u-button--secondary:focus,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:hover,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:focus {
  color: white;
  background-color: #01324b;
}
.app-masthead--pastel .c-pdf-download .u-button--secondary:hover svg path, .app-masthead--pastel .c-pdf-download .u-button--secondary:focus svg path,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:hover svg path,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:focus svg path {
  fill: white;
}
.app-masthead--pastel .c-pdf-download .u-button--secondary:hover,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:hover {
  border: 2px solid transparent;
  background-color: #025e8d;
}
.app-masthead--pastel .c-pdf-download .u-button--secondary:focus,
.c-context-bar--sticky .c-context-bar__container .c-pdf-download .u-button--secondary:focus {
  border: 4px solid #fc0;
  color: #01324b;
  background-color: #ffffff;
  background-image: none;
}

.app-article-masthead__syndicated-card a,
.app-article-masthead__syndicated-card a:visited {
  color: #000000;
}

@media only screen and (min-width: 768px) {
  .app-article-masthead {
    flex-direction: row;
    gap: 64px;
    padding: 24px 0;
  }
  .app-article-masthead__brand {
    border: 0;
    padding: 0;
  }
  .app-article-masthead__brand img {
    position: static;
    width: auto;
    height: auto;
  }
  .app-article-masthead__buttons {
    margin-top: auto;
    flex-direction: row;
    align-items: center;
  }
  .app-article-masthead__journal-link {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0;
    margin: 0 0 8px;
  }
  .app-article-masthead__conference-link {
    margin: 0;
  }
  .app-article-masthead__conference-info {
    padding: 8px 0 0;
  }
  .app-article-masthead__submission-link {
    margin: 0;
  }
  .app-article-masthead .c-pdf-container {
    flex-grow: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .app-article-masthead__brand {
    flex-basis: 400px;
  }
}
.app-article-masthead__buttons .c-pdf-container {
  justify-content: flex-start;
}

.app-article-masthead .c-article-identifiers {
  line-height: 1;
  margin: 0 0 8px;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 300;
  overflow: hidden;
}

.app-article-masthead .c-article-identifiers--cite-list {
  margin: 0 0 16px;
}

.app-article-masthead .c-article-identifiers *,
.app-article-masthead .c-article-identifiers a:hover,
.app-article-masthead .c-article-identifiers a:focus {
  color: #ffffff;
}

.app-article-masthead .c-cod {
  display: none;
}

.app-article-masthead .c-article-identifiers__item {
  margin: 0 17px 8px -9px;
  padding: 0 0 0 8px;
  border-right: 0;
  border-left: 1px solid #ffffff;
}

.app-article-masthead .c-article-identifiers__item--cite {
  border-left: 0;
}

.app-article-masthead__brand--no-border img {
  border: none;
}

@media only screen and (max-width: 767px) {
  .app-article-masthead-conference-info {
    padding: 0 0 0 88px;
  }
}
/**
 * Darwin article metrics
 * Article metrics styles for browsers that pass CTM
 */
.app-article-metrics {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media only screen and (min-width: 768px) {
  .app-article-metrics {
    flex-direction: row;
  }
}
.app-article-metrics .app-article-metrics-container {
  flex: 1 0 calc(50% - 24px);
}

.app-article-metrics-container {
  border: 1px solid #c5e0f4;
  border-radius: 12px;
  display: flex;
  padding: 16px;
  font-size: 0.875rem;
}

.app-article-metrics-box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  width: 100%;
}
.app-article-metrics-box__main {
  display: flex;
  flex-direction: column;
  flex: 1 1 75%;
}
@media only screen and (max-width: 767px) {
  .app-article-metrics-box__main {
    border-bottom: 1px solid #c5e0f4;
    padding-bottom: 16px;
  }
}
.app-article-metrics-box__side {
  display: flex;
  flex-direction: column;
  flex: 1 0 25%;
  justify-content: space-evenly;
}
@media only screen and (max-width: 767px) {
  .app-article-metrics-box__side {
    flex-direction: row;
  }
}
.app-article-metrics-box__side-top {
  justify-content: flex-start;
}
@media only screen and (max-width: 767px) {
  .app-article-metrics-box__side-top {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .app-article-metrics-box__side-column {
    flex-direction: column;
  }
}

.app-article-metrics-box p {
  margin-bottom: 8px;
}

.app-article-metrics-box__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8em;
  height: 1.8em;
  background-color: #f0f7fc;
  border-radius: 50%;
}

.app-article-metrics-box-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.app-article-metrics-box-icon__metrics {
  fill: none;
}

.app-article-metrics-box--wide__main {
  flex: 1 1 50%;
}

.app-article-metrics-box--wide__side {
  flex: 1 0 50%;
}
@media only screen and (max-width: 767px) {
  .app-article-metrics-box--wide__side {
    flex-direction: column;
  }
}

.app-article-metrics-stat {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  color: #000000;
  display: flex;
  line-height: 1.5;
  gap: 12px;
}

.app-article-metrics-stat__item {
  display: inline-block;
  font-weight: 700;
  text-align: center;
}
.app-article-metrics-stat__item:not(:last-child) {
  border-right: 1px solid #999;
  padding-right: 8px;
}
@media only screen and (max-width: 767px) {
  .app-article-metrics-stat__item {
    display: none;
  }
}

.app-article-metrics-stat__subitem {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
}

.app-article-metrics-count {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  font-size: 1.75rem;
  margin: 0;
}

.app-article-metrics-count_text {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
}

.c-article-metrics__altmetric-key {
  border-radius: 50%;
  display: block;
  float: left;
  height: 16px;
  margin: 4px 0 0 0;
  width: 16px;
}
.c-article-metrics__altmetric-key > span {
  font-weight: 700;
}
.c-article-metrics__altmetric-key--twitter {
  background-color: #2f90b9;
}
.c-article-metrics__altmetric-key--blogs {
  background-color: #ffc200;
}
.c-article-metrics__altmetric-key--facebook {
  background-color: #071d70;
}
.c-article-metrics__altmetric-key--googleplus {
  background-color: #912470;
}
.c-article-metrics__altmetric-key--news {
  background-color: #b60000;
}
.c-article-metrics__altmetric-key--linkedin {
  background-color: #00bfff;
}
.c-article-metrics__altmetric-key--reddit {
  background-color: #b9ddeb;
}
.c-article-metrics__altmetric-key--f1000 {
  background-color: #cb2d2d;
}
.c-article-metrics__altmetric-key--pinterest {
  background-color: #c30;
}
.c-article-metrics__altmetric-key--qna {
  background-color: #efefef;
}
.c-article-metrics__altmetric-key--video {
  background-color: #98c973;
}
.c-article-metrics__altmetric-key--wikipedia {
  background-color: #c7c7c7;
}
.c-article-metrics__altmetric-key--reviews {
  background-color: #d45bb0;
}
.c-article-metrics__altmetric-key--citeulike {
  background-color: #bcd2ef;
}
.c-article-metrics__altmetric-key--mendeley {
  background-color: #a60000;
}
.c-article-metrics__altmetric-key--connotea {
  background-color: #ff4040;
}
.c-article-metrics__altmetric-key + span {
  display: block;
  margin-left: 24px;
}

.app-article-metrics-altmetric {
  display: flex;
  gap: 48px;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .app-article-metrics-altmetric {
    display: block;
  }
}

.c-article-metrics__image {
  flex-shrink: 0;
}
@media only screen and (max-width: 767px) {
  .c-article-metrics__image {
    text-align: center;
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 876px) {
  .c-article-metrics__legend {
    flex-basis: 75%;
  }
}

.c-article-metrics__legend ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}
@media only screen and (min-width: 768px) {
  .c-article-metrics__legend ul {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1024px) {
  .c-article-metrics__legend ul {
    flex-direction: row;
  }
}
@media only screen and (max-width: 479px) {
  .c-article-metrics__legend ul {
    flex-direction: column;
  }
}

.c-article-metrics__legend li {
  flex: 1 0 calc(50% - 16px);
  margin-bottom: 8px;
}
@media only screen and (max-width: 767px) {
  .c-article-metrics__legend li {
    width: 100%;
    padding: 4px;
  }
}

.c-article-metrics-heading {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}

.c-article-metrics__title a {
  color: #222222;
}
.c-article-metrics__title a.visited, .c-article-metrics__title a:visited, .c-article-metrics__title a.hover, .c-article-metrics__title a:hover {
  color: #222222;
}

.c-article-metrics__updated {
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .app-article-metrics-box {
    flex-direction: row;
  }
  .app-article-metrics-box__main {
    border-right: 1px solid #c5e0f4;
    padding-right: 24px;
  }
}
.app-article-metrics-bar {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  padding: 16px 0 0;
  row-gap: 24px;
}
.app-article-metrics-bar__item {
  padding: 0 16px 0 0;
}
.app-article-metrics-bar__count {
  font-weight: 700;
}
.app-article-metrics-bar__label {
  font-weight: 400;
  padding-left: 4px;
}
.app-article-metrics-bar__icon {
  width: auto;
  height: auto;
  margin-right: 4px;
  margin-top: -4px;
}
.app-article-metrics-bar__arrow-icon {
  margin: 4px 0 0 4px;
}
.app-article-metrics-bar p {
  margin: 0;
}

.app-article-metrics-bar a,
.app-article-metrics-bar a:visited {
  color: #000000;
}

.app-article-metrics-bar .app-article-metrics-bar__item--metrics {
  padding-right: 0;
}

.app-cite-as {
  flex-basis: 100%;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 16px;
}

.app-cite-as a,
.app-cite-as a:visited {
  color: #000000;
}

.c-card-metrics {
  padding: 16px 0;
  border-bottom: 1px solid #c5e0f4;
}

ul li:last-child > .c-card-metrics {
  border-bottom: none;
}

@media only screen and (min-width: 480px) {
  .c-card-metrics {
    padding: 24px 0;
  }
}
.c-card-metrics__main {
  word-break: break-word;
}

.c-card-metrics__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2vw), 1.125rem);
}

.c-card-metrics__heading a {
  line-height: 1.5;
  color: #000000;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-card-metrics__authors {
  display: block;
}

.c-book-section,
.c-chapter-body .c-article-section {
  margin-bottom: 32px;
}

@media only screen and (min-width: 1024px) {
  .c-book-section,
  .c-chapter-body .c-article-section {
    margin-bottom: 64px;
  }
}
.app-overview-section {
  margin: 0 0 64px;
  line-height: 1.5;
  font-size: 1rem;
}
.app-overview-section__separator {
  padding: 24px 0 0;
  margin: 24px 0 0;
  border-top: 1px solid #c5e0f4;
}
.app-overview-section__authors dt {
  font-weight: 700;
  float: left;
  margin-right: 8px;
}
.app-overview-section__authors {
  line-height: 2;
}
.app-overview-section__authors > * {
  margin: 0;
}

.app-overview-section .c-article-author-list {
  line-height: 2;
}

.app-overview-section__book-series + .app-overview-section__conf-series {
  margin-top: 24px;
}

.app-book-overview-author-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-book-series-listing {
  display: flex;
  gap: 8px;
}
.app-book-series-listing__icon {
  fill: currentColor;
  width: 24px;
  height: 24px;
  vertical-align: text-bottom;
}
.app-book-series-listing__description {
  font-weight: 700;
  margin-bottom: 8px;
}
.app-book-series-listing__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-book-series-listing__item {
  padding-bottom: 8px;
}
.app-book-series-listing--sub-series {
  margin-top: 16px;
}

.app-book-series-listing__icon--chapter {
  margin-right: 8px;
  vertical-align: text-bottom;
}

.js .app-book-series-listing__list + .app-book__show-button {
  margin: 0;
}

.app-book-conference-proceedings {
  margin-top: 16px;
}

.app-book-series-listing__icon--arrow {
  transform: rotate(90deg);
}

.c-book-show-more-less li {
  display: block;
  visibility: visible;
}

.c-book-show-more-less li p {
  margin: 0;
  display: inline;
}

.app-article-metrics-bar {
  margin-top: 8px;
}

.c-book-section__title--book {
  margin: 0 0 24px;
}

@media only screen and (max-width: 1023px) {
  .c-book-section__title--book {
    margin: 0 0 16px;
  }
}
.js .app-book__show-button {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
  font-size: 0.875rem;
  background: none;
  border: none;
  padding: 0;
  margin-left: 24px;
}
.js .app-book__show-button.visited, .js .app-book__show-button:visited {
  color: #01324b;
}
.js .app-book__show-button.hover, .js .app-book__show-button:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.js .app-book__show-button.active, .js .app-book__show-button:active {
  color: #025e8d;
}
.js .app-book__show-button.focus, .js .app-book__show-button:focus {
  outline: 4px solid #0088cc;
}
.js .app-book__show-button svg {
  fill: currentColor;
  vertical-align: text-bottom;
  margin-right: 8px;
}

.js .c-list-bullets .app-book__show-button {
  margin-top: 8px;
}

@media only screen and (min-width: 1024px) {
  .js .app-book__show-button {
    font-size: 1rem;
  }
  .js .app-book__show-button svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.js .app-book__show-button--open svg {
  transform: rotate(180deg);
}

.app-overview-section .app-article-metrics-bar {
  padding-top: 0;
}

/* Buy box show/hide in main body within c-buybox__container depending on viewport */
.c-article-main-column .c-buybox__container {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .c-article-main-column .c-buybox__container {
    display: block;
  }
}
.app-book-sections {
  border: 1px solid #c5e0f4;
  border-radius: 8px;
  font-size: 1rem;
  padding: 24px;
}
.app-book-sections h2 {
  font-size: 1.125rem;
}
.app-book-sections__list {
  line-height: 1.5;
}
.app-book-sections__item:not(:last-child) {
  padding: 0 0 16px;
}

.app-book-search {
  border-radius: 8px;
  background-color: #f0f7fc;
  padding: 24px;
  margin-bottom: 32px;
}
.app-book-search__label {
  font-size: 1rem;
  font-weight: 700;
}
.app-book-search__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.app-book-search__input {
  flex: 1;
  font-size: 1rem;
  padding: 8px;
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .app-book-search__content {
    flex-direction: row;
  }
}
.c-pdf-chapter-download__link {
  font-size: 1rem;
  font-weight: 700;
  vertical-align: top;
}
.c-pdf-chapter-download__link svg {
  fill: currentColor;
  margin-left: 4px;
  margin-top: 2px;
  vertical-align: text-top;
}

@media only screen and (max-width: 479px) {
  .c-pdf-chapter-download__link--hide-small-screen {
    display: none;
  }
}
.c-book-toc li:first-child .app-card-open {
  padding-top: 0;
}

.c-book-section__back-to-top {
  margin-top: 32px;
}

.c-book-toc-pagination + .c-book-section__back-to-top {
  margin-top: 0;
}

.app-card-open__heading img {
  border: none;
  padding-right: 10px;
  aspect-ratio: auto;
}

.app-card-open--has-download-link .app-card-open__heading {
  max-width: calc(100vw - 70px - 16px - 16px - 16px);
}

@media only screen and (min-width: 480px) {
  .app-card-open--has-download-link .app-card-open__main {
    margin-right: 16px;
  }
  .app-card-open--has-download-link .app-card-open__heading {
    max-width: initial;
  }
}
.c-book-heading--underline {
  border-bottom: 1px solid #c5e0f4;
  margin-bottom: 24px;
  padding-bottom: 8px;
}

.c-book-part-heading--underline {
  border-bottom: 2px solid #222222;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.app-card-open {
  border-bottom: 1px solid #c5e0f4;
  border-top: 1px solid transparent;
}

.app-article-masthead--book {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  padding: 16px 0 24px;
  /* stylelint-disable declaration-colon-space-after */
  /* stylelint-disable indentation */
  grid-template-areas: "breadcrumbs breadcrumbs" "journal-cover imprints-and-societies" "info info";
  /* stylelint-enable declaration-colon-space-after */
  /* stylelint-enable indentation */
}
.app-article-masthead--book__breadcrumbs {
  grid-area: breadcrumbs;
}
.app-article-masthead--book__imprints-and-societies {
  grid-area: imprints-and-societies;
  justify-self: flex-end;
}
.app-article-masthead--book__title {
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
  word-break: break-word;
}
.app-article-masthead--book__title-link, .app-article-masthead--book__title-link:hover, .app-article-masthead--book__title-link:focus, .app-article-masthead--book__title-link:active, .app-article-masthead--book__title-link:visited {
  color: currentColor;
}

.app-article-masthead--book__info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
}
.app-article-masthead--book__info--book-title {
  margin: 0 0 16px 0;
}

p.app-article-masthead--book__subtitle {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 16px;
}

.app-article-masthead--book__journal-cover {
  grid-area: journal-cover;
}
.app-article-masthead--book__journal-cover img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  max-width: 90px;
}

@media only screen and (min-width: 480px) {
  .app-article-masthead--book {
    grid-template-columns: auto minmax(0, 1fr) auto;
    /* stylelint-disable declaration-colon-space-after */
    /* stylelint-disable indentation */
    grid-template-areas: "breadcrumbs breadcrumbs imprints-and-societies" "journal-cover info info";
    /* stylelint-enable declaration-colon-space-after */
    /* stylelint-enable indentation */
    padding: 24px 0 32px;
  }
  .app-article-masthead--book__journal-cover img {
    max-width: 160px;
  }
  .app-article-masthead--book__button--container {
    margin: auto 0 0;
    padding: 0;
  }
  .app-article-masthead--book__info {
    padding-left: 24px;
    line-height: 1.8;
  }
  .app-article-masthead--book__title {
    line-height: 1.2;
    font-size: 2.25rem;
  }
}
.c-book-other-volumes__list {
  padding-left: 32px;
}

.c-book-other-volumes__item {
  padding: 0 0 16px 4px;
  position: relative;
}
.c-book-other-volumes__item:last-child {
  padding-bottom: 0;
}
.c-book-other-volumes__item--title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  line-height: 1.8;
  font-weight: 400;
  margin: 0;
}

.c-book-other-volumes__item--highlighted::before {
  content: "";
  position: absolute;
  top: 0;
  left: -32px;
  bottom: 0;
  width: 4px;
  background: #0088cc;
  border-radius: 4px;
}

.c-book-other-volumes__item--highlighted .c-book-other-volumes__item--title {
  font-weight: 700;
}

.c-book-other-volumes__item a,
.c-book-other-volumes__item a:visited,
.c-bibliographic-information__list-item a,
.c-bibliographic-information__list-item a:visited {
  color: #000000;
}

/* below styles are a subset of lib/frontend/sites/oscar-sites-springer/src/app-springerlink/scss/partials/darwin/_list-societies.scss */
.app-societies__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 0;
  margin-top: 24px;
}
@media only screen and (min-width: 768px) {
  .app-societies__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 16px;
  }
}

.app-societies__list-item {
  display: grid;
  align-items: stretch;
}

.app-societies__list-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: #222222;
  padding: 24px;
  border: 1px solid #c5e0f4;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
}
@media only screen and (min-width: 768px) {
  .app-societies__list-content {
    flex-basis: calc(50% - 8px);
    flex-direction: row;
  }
}
.app-societies__list-content:hover, .app-societies__list-content:visited {
  color: #222222;
}

.app-societies__list-image {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
}
.app-societies__list-image img {
  max-width: 125px;
  max-height: 72px;
  width: auto;
  height: auto;
}

.app-societies__list-text {
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .app-societies__list-text {
    text-align: left;
  }
}

.app-societies__list-external-link-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  vertical-align: middle;
  fill: #222222;
}

.app-article-masthead--book {
  flex-direction: column;
  gap: 24px 16px;
}

.app-article-masthead .c-article-identifiers__copyright {
  vertical-align: text-bottom;
}

.c-article-body .c-article-access-provider__text--chapter {
  color: #222222;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px 0;
}
.c-article-body .c-article-access-provider__text--chapter svg.c-status-message__icon {
  fill: #003F8D;
  vertical-align: middle;
}

.c-article-body-section__content--separator {
  padding-top: 40px;
}

.app-article-masthead--book .app-article-masthead__journal-link {
  padding: 0;
}

.app-article-masthead--chapter .app-article-masthead__brand {
  min-height: 115px;
}

@media only screen and (max-width: 1023px) {
  .app-article-masthead--chapter .c-pdf-download .u-sticky-visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }
  .app-article-masthead--chapter .c-pdf-download .c-pdf-download__text {
    text-transform: capitalize;
  }
}
.app-article-masthead--chapter .app-article-masthead__brand {
  flex-shrink: 1;
}

@media only screen and (min-width: 1024px) {
  .app-article-masthead--chapter .app-article-masthead__brand {
    flex-shrink: 0;
  }
}
.c-pdf-download__link {
  max-height: 44px;
}

@media only screen and (max-width: 767px) {
  .app-article-masthead--book__info .c-pdf-container,
  .app-article-masthead__info .c-pdf-container {
    flex-direction: column;
    gap: 12px;
  }
  .app-article-masthead--book__info .c-pdf-container .c-pdf-download + .c-pdf-download,
  .app-article-masthead__info .c-pdf-container .c-pdf-download + .c-pdf-download {
    margin: 0;
  }
}
.c-book-author-list--expanded .u-js-hide {
  visibility: visible;
  display: inline;
}

.c-book-author-list--expanded .u-js-show-inline {
  display: none;
}

/* Hide the buy box when theres no pricing content. */
.c-book-buy-box:not(:has(.price-info)) {
  display: none;
}

/* but override that if there is a access button. */
.c-book-buy-box:has(.u-button) {
  display: block !important;
}

.app-author-affiliation-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-author-affiliation-list__item {
  font-size: 1.125rem;
  margin: 0 0 32px;
}
.app-author-affiliation-list__title {
  font-size: 1.125rem;
  margin: 0;
  padding-bottom: 8px;
}

.c-reading-companion__section-item a:visited {
  color: #025e8d;
}

.c-expand-overlay-wrapper {
  position: relative;
}

.app-article-masthead--book .app-article-masthead__journal-link {
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .app-article-masthead--book .app-article-masthead__journal-link {
    display: flex;
  }
}
.c-expand-overlay--book {
  border-radius: 4px;
}

.app-article-policy-section-external-link {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.8;
  color: #222;
  font-size: 1rem;
}
.app-article-policy-section-external-link:visited {
  color: #222;
}

.app-article-policy-section-external-link-icon {
  vertical-align: middle;
  margin-left: 4px;
}

/* Added until we can update the critical css. */
.app-article-access .u-button--primary,
.app-article-access .u-button--primary:visited {
  color: #fff;
}

.c-author-popup__author-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
}
.c-author-popup__subheading {
  font-weight: 700;
  margin: 0;
}
.c-author-popup__footer-heading {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
.c-author-popup__author-list {
  list-style: none;
  font-size: 1rem;
  padding: 0;
  margin-top: 0;
  clear: both;
  margin-bottom: 16px;
}
.c-author-popup__author-list > li {
  margin-bottom: 8px;
}
.c-author-popup__link {
  font-weight: 700;
  vertical-align: baseline;
  color: #025e8d;
  text-decoration: none;
}

.c-author-popup .c-article-button {
  color: white;
  background: #025e8d;
  border: 1px solid #025e8d;
  border-radius: 2px;
  text-decoration: none;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: center;
  white-space: nowrap;
}
.c-author-popup .c-article-button:visited {
  color: white;
}
.c-author-popup .c-article-button:hover {
  color: #025e8d;
  background: white;
  text-decoration: none;
}

.c-author-popup .c-article-authors-search__list {
  clear: both;
}
@media only screen and (min-width: 876px) {
  .c-author-popup .c-article-authors-search__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 876px) {
  .c-author-popup .c-article-authors-search__list-item--left {
    flex-basis: 40%;
  }
}
.c-author-popup .c-article-authors-search__list-item--right {
  margin-top: 16px;
  margin-left: 8px;
}
@media only screen and (min-width: 876px) {
  .c-author-popup .c-article-authors-search__list-item--right {
    text-align: right;
    flex: 1;
    margin-top: 0;
  }
}

@media only screen and (min-width: 876px) {
  .c-author-popup .c-article-identifiers {
    justify-content: flex-end;
  }
}

.c-author-popup .c-article-authors-search__item,
.c-author-popup .c-article-identifiers {
  font-size: 1rem;
}

@media only screen and (min-width: 876px) {
  .c-author-popup .c-article-identifiers {
    justify-content: flex-end;
  }
}

.app-researcher-popup__author-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
}
.app-researcher-popup__footer-heading {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
.app-researcher-popup__author-list {
  list-style: none;
  font-size: 1rem;
  padding: 0;
  margin-top: 0;
  clear: both;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-researcher-popup__author-list {
    margin-bottom: 24px;
  }
}
.app-researcher-popup__author-list > li {
  margin-bottom: 8px;
  font-size: 0.875rem;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup__author-list > li {
    font-size: 1rem;
  }
}
.app-researcher-popup__link {
  color: inherit;
  color: #222222;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
}
.app-researcher-popup__link.visited, .app-researcher-popup__link:visited {
  color: inherit;
}
.app-researcher-popup__link.hover, .app-researcher-popup__link:hover {
  color: inherit;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup__link {
    font-size: 1rem;
  }
}
.app-researcher-popup__label {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 700;
}
.app-researcher-popup__subheading {
  margin: 4px 0 8px 0;
  font-weight: 700;
  font-size: 20px;
}
.app-researcher-popup__contacts {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
  padding: 16px 0;
  margin-bottom: 24px;
  border-top: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
}
.app-researcher-popup__contact-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.app-researcher-popup__orcid-icon {
  background-color: #a6ce39;
  fill: #ffffff;
  border-radius: 50%;
}
.app-researcher-popup .c-article-authors-search__list {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup .c-article-authors-search__list {
    justify-content: normal;
  }
}
.app-researcher-popup .c-article-authors-search__text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.875rem;
  flex-direction: column;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup .c-article-authors-search__text {
    font-size: 1rem;
    flex-direction: row;
  }
}
.app-researcher-popup .c-article-authors-search__links-text {
  font-weight: 700;
  margin-right: 8px;
  text-align: center;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup .c-article-authors-search__links-text {
    text-align: left;
  }
}
.app-researcher-popup .c-article-authors-search__list-item--left {
  flex: 1 1 auto;
  flex-basis: 100%;
}
.app-researcher-popup .c-article-authors-search__list-item--left a {
  width: 100%;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup .c-article-authors-search__list-item--left {
    flex-basis: auto;
  }
}
.app-researcher-popup .c-article-authors-search__list-item--left .c-article-authors-search__cta-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup .c-article-authors-search__list-item--left .c-article-authors-search__cta-icon {
    fill: currentColor;
    transform: translate(0, 0);
    display: inline-block;
    vertical-align: text-top;
    width: 1.5rem;
    height: 1.5rem;
  }
}
.app-researcher-popup .c-article-authors-search__list-item--right {
  flex: 1 1 auto;
}
.app-researcher-popup .c-article-identifiers {
  margin: 0;
}
.app-researcher-popup .c-article-identifiers__item {
  color: #222222;
  border-right: 2px solid #c5e0f4;
  font-size: 0.875rem;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup .c-article-identifiers__item {
    font-size: 1rem;
  }
}
.app-researcher-popup .c-article-identifiers__item:last-child {
  border-right: none;
}
.app-researcher-popup .c-article-authors-search__cta-link {
  font-size: 0.875rem;
}
@media only screen and (min-width: 480px) {
  .app-researcher-popup .c-article-authors-search__cta-link {
    font-size: 1rem;
  }
}

/* New Darwin styles */
h1,
h2,
h3,
h4,
h5 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c-article-main a[href*="#"]:visited {
  color: #025e8d;
}

button:focus:focus {
  outline: 3px solid #0088cc;
  will-change: transform;
}

input:not([disabled]):focus:focus {
  outline: 3px solid #0088cc;
  will-change: transform;
}

*:not(button, a[href]):focus {
  outline: 0;
}

.c-pdf-container {
  flex-grow: 1;
}

.c-article-sidebar {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .c-article-sidebar {
    display: block;
  }
}
.c-article-main .c-article-cod-access,
.c-article-main-column .c-article-cod-access,
.c-article-buy-box--article {
  display: block;
}

@media only screen and (min-width: 1024px) {
  .c-article-main .c-article-cod-access,
  .c-article-main-column .c-article-cod-access,
  .c-article-buy-box--article {
    display: none;
  }
}
.c-cod__form {
  border-radius: 12px;
}

.c-cod__label {
  font-size: 0.875rem;
}

.c-cod .c-status-message {
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  justify-content: center;
}

@media only screen and (min-width: 1024px) {
  .c-cod .c-status-message {
    align-items: inherit;
  }
}
.c-cod .c-status-message__icon {
  margin-top: 4px;
}

.c-cod .c-cod__prompt {
  font-size: 1rem;
  margin-bottom: 16px;
}

.c-external-link__icon {
  fill: #025e8d;
  vertical-align: middle;
}

.c-book-body .app-article-access,
.c-article-body .app-article-access {
  display: block;
}

@media only screen and (min-width: 1024px) {
  .c-book-body .app-article-access,
  .c-article-body .app-article-access {
    display: none;
  }
}
.c-article-body .app-card-service {
  margin-bottom: 32px;
}

@media only screen and (min-width: 1024px) {
  .c-article-body .app-card-service {
    display: none;
  }
}
.app-article-access .u-button--primary,
.app-article-access .buybox__buy .u-button--secondary,
.c-cod__row .u-button--primary {
  background-color: #025e8d;
  border: 2px solid #025e8d;
  box-shadow: none;
  line-height: 1.5;
  justify-content: center;
  gap: 8px;
  font-size: 1rem;
  font-weight: 700;
  padding: 8px 24px;
}
.app-article-access .u-button--primary:hover,
.app-article-access .buybox__buy .u-button--secondary:hover,
.c-cod__row .u-button--primary:hover {
  background-color: #ffffff;
  color: #025e8d;
}

.app-article-access .buybox__buy .u-button--secondary {
  background-color: #ffffff;
  color: #025e8d;
}
.app-article-access .buybox__buy .u-button--secondary:hover {
  background-color: #025e8d;
  color: #ffffff;
}

.buybox__buy .c-notes__text {
  color: #666666;
  font-size: 0.875rem;
  padding: 0 16px 8px;
}

.c-cod__input {
  flex-basis: auto;
  width: 100%;
}

/* Overrides for common article styles */
.c-article-title {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2.25rem;
  font-size: min(max(1.5rem, 4vw), 2.25rem);
  line-height: 1.2;
  margin: 12px 0;
}

.c-article-title--long {
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3vw), 1.75rem);
}

.c-article-title__super {
  color: #ffffff;
  line-height: 1.4;
  margin: 16px 0 -16px;
}

.c-article-title__sub {
  margin: 0;
}
.c-article-title__sub:last-of-type {
  margin-bottom: 24px;
}

@media only screen and (min-width: 768px) {
  .c-article-title {
    margin: 16px 0;
  }
}
.c-article-section__title {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3vw), 1.75rem);
  font-weight: 700;
  border-bottom: 1px solid #c5e0f4;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.app-article-access {
  border-radius: 12px;
  border: 1px solid #c5e0f4;
}
.app-article-access__heading {
  border-bottom: 1px solid #c5e0f4;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  text-align: center;
  font-weight: 700;
  padding: 16px;
  margin: 0;
}
.app-article-access__subscriptions {
  border-top: 1px solid #c5e0f4;
  padding: 16px;
}
.app-article-access__subscriptions svg {
  vertical-align: middle;
}

.app-article-access__heading--top-border {
  border-top: 1px solid #c5e0f4;
  border-bottom: none;
}

.c-article-body .app-article-access p {
  margin-bottom: 0;
}

.app-article-access .buybox__info,
.app-article-access .app-article-access__subscriptions {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
}

.app-article-access .buybox__info svg {
  vertical-align: middle;
}

.app-article-access {
  margin: 0 0 32px;
}

@media only screen and (min-width: 1024px) {
  .app-article-access {
    margin: 0 0 24px;
  }
}
.app-checklist-banner {
  border-radius: 12px;
  border: 1px solid #c5e0f4;
  margin: -16px 0 48px;
}

.app-checklist-banner__title {
  margin: 0 0 8px;
}

@media only screen and (min-width: 1024px) {
  .app-checklist-banner {
    margin: 0 0 24px;
  }
}
.c-article__sub-heading {
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-weight: 700;
}

.c-article__sub-heading--small {
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 2vw), 1.25rem);
  font-weight: 700;
}

.c-status-message {
  font-size: 1rem;
  font-size: min(max(0.875rem, 2vw), 1rem);
}

.c-article-section {
  line-height: 1.8;
  margin-bottom: 48px;
}

.c-article-body {
  font-size: 1.125rem;
  font-size: min(max(1rem, 2vw), 1.125rem);
}

.c-article-body p,
.c-article-body ul,
.c-article-body ol,
.c-article-body dl {
  margin-top: 0;
  margin-bottom: 32px;
}

.c-article-access-provider,
.c-notes {
  padding: 16px;
  margin-bottom: 32px;
}

.c-notes__text {
  color: inherit;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-article-access-provider__text:last-of-type,
.c-article-body .c-notes__text:last-of-type {
  margin-bottom: 0;
}

.c-article-body ul p,
.c-article-body ol p {
  margin-bottom: 16px;
}

.c-article-section__figure,
.c-article-table {
  padding: 24px 16px;
  margin: 0 -16px 48px -16px;
  border: 0;
  border-top: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
  max-width: 100vw;
}

.c-article-box {
  border: 0;
  background: #ebf1f5;
  margin: 0 0 48px -16px;
  width: 100vw;
}

.c-article-box__fade {
  background: radial-gradient(ellipse at bottom center, rgb(255, 255, 255), rgba(255, 255, 255, 0));
}

.c-article-box__container {
  padding: 32px 16px 12px;
}

.c-article-box__container-title {
  font-size: 1.25rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 0 24px;
}

.c-article-box__controls button {
  background: #ebf1f5;
  border-top: 1px solid #c5e0f4;
  padding: 16px;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: #222222;
}

.c-article-box__button-text::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22i-chevron-more%22%20viewBox%3D%220%200%2010%2010%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22m5.58578644%206-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927%200-1.41421356s1.02368927-.39052429%201.41421356%200l4%204c.39052429.39052429.39052429%201.02368927%200%201.41421356l-4%204.00000002c-.39052429.3905243-1.02368927.3905243-1.41421356%200s-.39052429-1.02368929%200-1.41421358z%22%20fill%3D%22currentColor%22%20fill-rule%3D%22evenodd%22%20transform%3D%22matrix%280%201%20-1%200%2011%201%29%22/%3E%3C/svg%3E");
}

@media only screen and (min-width: 1024px) {
  .c-article-section__figure,
  .c-article-table {
    padding: 24px;
    margin: 0 0 48px;
    border: 1px solid #c5e0f4;
    border-radius: 12px;
    width: auto;
  }
  .c-article-box {
    width: auto;
    margin: 0 0 48px;
    border-radius: 12px;
  }
  .c-article-box__container {
    padding: 32px 24px 12px;
  }
  .c-article-box__controls button {
    border-radius: 0 0 12px 12px;
  }
}
.c-article-section__figure-caption,
.c-article-table__figcaption {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-article-section__figure figure,
.c-article-table figure,
.c-reading-companion__figure-item figure {
  margin: 0;
}

.c-reading-companion__figure-item {
  border-top-color: #c5e0f4;
}

.c-reading-companion__sticky {
  max-width: 400px;
}

.c-article-section .c-article-section__figure-description > * {
  font-size: 1rem;
  font-size: min(max(0.875rem, 2vw), 1rem);
  margin-bottom: 16px;
}

.c-reading-companion__reference-item {
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  padding: 16px 0;
}
.c-reading-companion__reference-item:first-child {
  padding-top: 0;
}

.c-article-share-box__button,
.c-article__pill-button,
.js .c-article-authors-search__item .c-article-button {
  background: transparent;
  color: #025e8d;
  line-height: 1.5;
  transition: all 0.2s;
  border-radius: 32px;
  border: 2px solid #025e8d;
  font-size: 1rem;
  font-weight: 700;
  box-shadow: none;
  padding: 8px 24px;
  margin: 0;
}

.c-article-share-box__button:visited,
.c-article__pill-button:visited,
.c-article-authors-search__item .c-article-button:visited {
  color: #025e8d;
}

.c-article-share-box__button:hover,
.c-article__pill-button:hover,
.c-article-authors-search__item .c-article-button:hover {
  background-color: #025e8d;
  color: #ffffff;
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}

.c-article-authors-search__item .c-article-button {
  width: 100%;
}

.c-pdf-download {
  max-height: none;
  margin-bottom: 0;
}

.c-pdf-download .u-button {
  color: #01324b;
  background-color: white;
  border: 4px solid white;
  font-weight: 700;
  font-size: 1.125rem;
  padding: 10px 35px;
  border: 2px solid #ffffff;
  justify-content: center;
}
.c-pdf-download .u-button svg {
  fill: currentColor;
}
.c-pdf-download .u-button:visited {
  color: #01324b;
}
.c-pdf-download .u-button:hover {
  border: 4px solid #01324b;
  box-shadow: none;
}
.c-pdf-download .u-button:hover, .c-pdf-download .u-button:focus {
  color: white;
  background-color: #01324b;
}
.c-pdf-download .u-button:hover svg path, .c-pdf-download .u-button:focus svg path {
  fill: white;
}

.c-context-bar__container .c-pdf-download .u-button {
  color: white;
  background-color: #01324b;
  background-image: none;
  border: 4px solid transparent;
  box-shadow: 0 0 0 1px #01324b;
  font-weight: 700;
  font-size: 1.125rem;
  padding: 10px 35px;
  border: 2px solid currentColor;
}
.c-context-bar__container .c-pdf-download .u-button svg {
  fill: currentColor;
}
.c-context-bar__container .c-pdf-download .u-button:visited {
  color: white;
}
.c-context-bar__container .c-pdf-download .u-button:hover {
  border: 4px solid white;
  box-shadow: 0 0 0 1px #01324b;
  text-decoration: none;
}
.c-context-bar__container .c-pdf-download .u-button:focus {
  border: 4px solid #ffcc00;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}
.c-context-bar__container .c-pdf-download .u-button:hover, .c-context-bar__container .c-pdf-download .u-button:focus {
  color: #01324b;
  background-color: white;
  background-image: none;
}
.c-context-bar__container .c-pdf-download .u-button:hover svg path, .c-context-bar__container .c-pdf-download .u-button:focus svg path {
  fill: #01324b;
}

.c-pdf-download .u-button,
.c-context-bar__container .c-pdf-download .u-button {
  font-size: 1rem;
  font-weight: 700;
  box-shadow: none;
  line-height: 1.5;
  padding: 8px 24px;
}

.c-context-bar__container .c-pdf-download .u-button {
  background-color: #025e8d;
}

.c-pdf-download .u-button:hover,
.c-pdf-download .u-button:focus {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  box-shadow: none;
}

.c-context-bar__container .c-pdf-download .u-button:hover {
  border: 2px solid #025e8d;
  box-shadow: none;
  color: #025e8d;
}

.c-pdf-download .u-button:focus,
.c-context-bar__container .c-pdf-download .u-button:focus {
  border: 2px solid #025e8d;
}
.c-pdf-download .u-button:focus:focus,
.c-context-bar__container .c-pdf-download .u-button:focus:focus {
  outline: 3px solid #0088cc;
  will-change: transform;
}

.c-article-share-box__button:focus,
.c-article__pill-button:focus {
  border: 2px solid currentColor;
}
.c-article-share-box__button:focus:focus,
.c-article__pill-button:focus:focus {
  outline: 3px solid #0088cc;
  will-change: transform;
}

.c-pdf-download__link .u-icon {
  padding-top: 0;
}

.c-article-references p,
.c-bibliographic-information__column p,
.c-bibliographic-information__column button {
  margin-bottom: 16px;
}

.c-bibliographic-information__column .c-bibliographic-information__list-item p,
.c-bibliographic-information__list-item--doi {
  margin-bottom: 0;
}

.c-bibliographic-information__list-item--chapter-doi {
  margin-bottom: 16px;
}

.c-bibliographic-information {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.c-bibliographic-information h4 {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
}
.c-bibliographic-information__multi-value {
  display: block;
  font-size: 1rem;
  margin-bottom: 0;
}

.c-bibliographic-information__column--border {
  border-color: #c5e0f4;
}

.c-bibliographic-information__download-citation-list {
  list-style: none;
  margin: 0 0 16px;
  padding: 0;
}

.c-bibliographic-information__download-citation-item {
  display: inline-block;
  font-size: 1rem;
  margin-right: 8px;
}
.c-bibliographic-information__download-citation-item svg {
  margin: 1px 8px 0 0;
}

.c-article-references__item {
  border: 0;
}

.c-article-body .c-article-author-affiliation__list p,
.c-article-body .c-article-author-information__list p {
  margin: 0;
}

.c-article-metrics-bar__wrapper {
  margin: 16px 0;
}

.c-blockquote {
  border-color: #3c9cd7;
}

.c-article-subject-list__subject {
  background: #ebf1f5;
  padding: 8px 24px;
  border-radius: 32px;
  font-size: 0.875rem;
  margin-right: 8px;
  margin-bottom: 8px;
}

.c-recommendations-visibility,
#recommendations {
  background: #eff6fb;
}

.c-recommendations-visibility .c-recommendations-list:after {
  background: linear-gradient(transparent 85%, #eff6fb);
}

.c-card__title-recommendation .c-card__link {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.c-context-bar--sticky .c-context-bar__container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
}

.c-context-bar--sticky .c-context-bar__title {
  display: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  text-overflow: initial;
  align-self: center;
}

.c-context-bar--sticky .c-context-bar__container > div:not(.c-context-bar__title) {
  display: flex;
  flex-grow: 1;
}

@media only screen and (min-width: 480px) {
  .c-context-bar--sticky .c-context-bar__title {
    display: -webkit-box;
  }
  .c-context-bar--sticky .c-context-bar__container > div:not(.c-context-bar__title) {
    flex-grow: 0;
  }
}
.c-reading-companion__tab {
  color: #025e8d;
  background-color: #eeeeee;
}

.c-reading-companion__tab--active {
  color: #222222;
  background: #ffffff;
}

.c-article-share-box__button {
  margin-right: 16px;
}

.c-status-message--boxed {
  border-radius: 12px;
}

.c-article-associated-content__container .c-article-associated-content__title {
  border-bottom: 0;
  margin-bottom: 0;
}

.c-article-associated-content__collection-title {
  font-size: 1rem;
}

.app-card-service {
  border-top: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
  font-size: 1rem;
  margin: -16px 0 48px;
  padding: 16px 0;
}

@media only screen and (min-width: 1024px) {
  .app-card-service {
    border-top: none;
    margin: 4px 0 24px;
    padding: 0 0 16px;
  }
}
.app-card-service__description,
.c-article-body .app-card-service__description {
  color: #222222;
  margin-bottom: 0;
  margin-top: 8px;
}

.c-article-satellite-subtitle {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3vw), 1.75rem);
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 24px;
}

.c-breadcrumbs__link,
.c-breadcrumbs__link:visited,
.c-breadcrumbs__link:hover,
.c-article-buy-box a,
.c-article-buy-box a:visited,
.app-article-access__subscriptions a,
.app-article-access__subscriptions a:visited,
.c-article-author-list a,
.c-article-author-list a:visited,
.c-article-satellite-subtitle a,
.c-article-satellite-subtitle a:visited,
.c-article-peer-review a,
.c-article-peer-review a:visited,
.app-book-series-listing__item a,
.app-book-series-listing__item a:visited,
.app-book-series-listing__item a:hover {
  color: #000000;
}

.c-article-author-list svg {
  margin: 0 0 0 6px;
  width: 24px;
  height: 24px;
}

.c-article-further-reading__title {
  font-size: 1.125rem;
  font-size: min(max(1rem, 2vw), 1.125rem);
  font-weight: 700;
}

.app-pdf-preview {
  display: none;
}

.app-pdf-preview__frame {
  width: 100%;
  height: 600px;
  border: 0;
  border-radius: 12px;
}

@media only screen and (min-width: 480px) {
  .app-pdf-preview {
    display: block;
  }
  .app-pdf-preview + .c-article-access-provider {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .app-card-service__link-icon {
    fill: currentColor;
  }
}
.c-article-header {
  margin-bottom: 32px;
}

.c-article-recommendations {
  border-radius: 8px;
}

.c-recommendations-list-arrow__previous svg {
  transform: scaleX(-1) translate(0);
}

.c-recommendations-list-arrow__next svg {
  transform: translate(0);
}

.c-article-recommendations-card img,
.c-card__image img {
  border: 1px solid #cfd8dc;
  border-radius: 8px;
}

/* Used on video pages that were fullwidth on nature */
.c-article-fullwidth-content__caption {
  margin-top: 16px;
}

.c-account-nav__anchor.is-open.eds-c-header__link::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzAyNWU4ZCIgaWQ9ImVkcy1pLXVzZXItc2luZ2xlLW1lZGl1bSI+PHBhdGggZD0iTTEyIDFhNSA1IDAgMSAxIDAgMTAgNSA1IDAgMCAxIDAtMTBabTAgMmEzIDMgMCAxIDAgMCA2IDMgMyAwIDAgMCAwLTZabS0uNDA2IDkuMDA4YTguOTY1IDguOTY1IDAgMCAxIDYuNTk2IDIuNDk0QTkuMTYxIDkuMTYxIDAgMCAxIDIxIDIxLjAyNVYyMmExIDEgMCAwIDEtMSAxSDRhMSAxIDAgMCAxLTEtMXYtLjk4NWMuMDUtNC44MjUgMy44MTUtOC43NzcgOC41OTQtOS4wMDdabS4zOSAxLjk5Mi0uMjk5LjAwNmMtMy42My4xNzUtNi41MTggMy4xMjctNi42NzggNi43NzVMNSAyMWgxMy45OThsLS4wMDktLjI2OGE3LjE1NyA3LjE1NyAwIDAgMC0xLjk3LTQuNTczbC0uMjE0LS4yMTNBNi45NjcgNi45NjcgMCAwIDAgMTEuOTg0IDE0WiIvPjwvc3ZnPgo=");
}

.app-peer-review {
  margin: 0 0 36px;
  border: 1px solid #c5e0f4;
  border-left: 10px solid #3c9cd7;
  border-radius: 8px;
  padding: 16px;
  line-height: normal;
}
.app-peer-review__link {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #025e8d;
}
.app-peer-review__link:hover, .app-peer-review__link:visited {
  color: #025e8d;
}
.app-peer-review__icon {
  margin-right: 4px;
  height: 24px;
  width: 24px;
}

.c-article-peer-review-external-icon {
  margin-left: 4px;
  vertical-align: middle;
}

@media only screen and (min-width: 876px) {
  .js .c-ad--conditional {
    display: block;
  }
}
.u-lazy-ad-wrapper {
  min-height: 149px;
  display: none;
  background-color: #fff;
}

@media only screen and (min-width: 876px) {
  .u-lazy-ad-wrapper {
    display: block;
  }
}
/* Override bottom margin from elements/layout.scss */
p.c-ad__label {
  margin-bottom: 4px;
}

.c-ad--728x90,
.c-ad--970x90 {
  background-color: #fff;
  border-bottom: 2px solid #cedbe0;
}

.c-ad--300x250 {
  background-color: #fff;
}

.c-reading-companion .c-ad {
  text-align: left;
}

figure {
  margin: 0;
}

.c-download-pdf-icon-large {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  fill: #025e8d;
}

.c-article-history {
  border-color: #0070a8;
}

.c-article-history a {
  color: #222222;
}

.c-article-history-item::before {
  border-color: #0070a8;
}

.c-article-history-item--current::before {
  background: #0070a8;
}

.c-cover-image-lightbox {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in;
}

.js-cover-image-lightbox--show {
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}

.js-cover-image-lightbox--close {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.75rem;
  padding: 13px;
  position: absolute;
  top: 0;
  right: 10px;
}

.c-cover-image-container {
  position: relative;
}

.c-cover-image-default {
  color: #000000;
  font-size: 0.875rem;
  position: absolute;
  bottom: 5px;
  left: 10px;
  margin: 0;
}

.c-cover-image-lightbox__image {
  max-height: 90vh;
  width: auto;
}

.c-expand-overlay-wrapper {
  position: relative;
}

.c-expand-overlay {
  background: #ffffff;
  color: #333;
  opacity: 0.5;
  padding: 2px;
  position: absolute;
  top: 3px;
  right: 3px;
}

.c-cover-expanded-txt {
  font-size: 0.875rem;
  margin-top: 8px;
}